import React, { useEffect } from 'react';
import { Loading } from '@fairstone/ui/core/components/Loading';
import isEmpty from 'lodash/isEmpty';

import { setApiKey } from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { getMerchantConfigAsync, merchantState } from 'store/redux/modules/merchant';
import { getMerchantParam } from 'utils/url/getMerchantParam';
import { redirect } from 'utils/url/redirect';

export const MerchantProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const { config, error, loading } = useAppSelector(merchantState);
    const merchantID = getMerchantParam();

    useEffect(() => {
        if (!isEmpty(merchantID)) {
            dispatch(getMerchantConfigAsync(merchantID));
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(config)) {
            setApiKey(config.merchantApiKey);
        }
    }, [config]);

    useEffect(() => {
        if (error && !isEmpty(merchantID)) {
            redirect('404');
        }
    }, [error]);

    return <>{(isEmpty(config) || loading) && !isEmpty(merchantID) ? <Loading /> : children}</>;
};
