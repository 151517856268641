import React from 'react';
import { LocaleSwitcher } from '@fairstone/ui/core/components/utils/LocaleSwitcher';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { Logo } from 'components/Logo';
import { localesMapping } from 'locales';

import styles from './Header.module.scss';

interface IHeader {
    className?: string;
    showLocale: boolean;
}
export const Header: React.FC<IHeader> = ({ className, showLocale }) => (
    <header className={cx(className, styles.header)}>
        <div>
            <Logo />
        </div>
        <div>
            {showLocale && (
                <LocaleSwitcher
                    ariaLabel={translate('localeSwitcher.aria-label')}
                    className={styles.switcher}
                    label={t('localeSwitcher.label')}
                    localesMapping={localesMapping}
                />
            )}
        </div>
    </header>
);
