import * as yup from 'yup';

const alphaNumericRegex = new RegExp(/^([A-Za-z0-9 ])+$/);

export const salesTrackingValidation = yup.object().shape({
    assistance: yup.string().required('pages.splash.salesTrackingDialog.error.required'),
    trackingId: yup.string().when('assistance', {
        is: (assistance: string) => !!assistance && assistance === 'yes',
        otherwise: (schema) => schema.notRequired(),
        then: (schema) =>
            schema
                .required('pages.splash.salesTrackingDialog.error.required')
                .trim()
                .matches(alphaNumericRegex, 'pages.splash.salesTrackingDialog.error.invalid')
                .max(8, 'pages.splash.salesTrackingDialog.error.length'),
    }),
});

// Extract TypeScript type from Yup schema
export type TSalesTracking = yup.InferType<typeof salesTrackingValidation>;
