import { useEffect, useState } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

interface IUseMaintenance {
    inMaintenance: boolean;
    tuesday: boolean;
}

export const useMaintenance = (): IUseMaintenance => {
    const [inMaintenance, setInMaintenance] = useState(false);
    const [tuesday, setTuesday] = useState(false);

    useEffect(() => {
        const date = Date.now();
        const est = formatInTimeZone(date, 'America/New_York', 'EEEE HH', { roundingMethod: 'ceil' });
        const weekday = est.split(' ')[0];
        const time = est.split(' ')[1];

        setInMaintenance((weekday !== 'Saturday' && time === '01') || (weekday === 'Tuesday' && time === '00'));
        setTuesday(weekday === 'Tuesday' && time === '00');
    }, []);

    return { inMaintenance, tuesday };
};
