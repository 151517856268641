import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';

import { useAppDispatch } from 'store/redux/hooks';
import { setKioskURL, setType } from 'store/redux/modules/session';
import { ESessionTypes } from 'store/redux/modules/session/types';
interface jwtToken {
    [key: string]: string | number | boolean;
}

interface IUseTokenRes {
    currentSessionId: string;
    encodedIncreditToken?: string;
}
export const useToken = (): IUseTokenRes => {
    const dispatch = useAppDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const kioskToken = urlParams.get('kiosktoken') as string;
    const encodedIncreditToken = urlParams.get('incredittoken') as string;
    const [currentSessionId, setCurrentSession] = useState('');

    useEffect(() => {
        if (kioskToken) {
            const decoded: jwtToken = jwt_decode(kioskToken as string);
            setCurrentSession(decoded.sessionId as string);
            let kioskURL = decoded.kioskUrl as string;
            if (kioskURL.indexOf('?') > -1) {
                const idpId = kioskURL.substring(kioskURL.indexOf('?') + 1, kioskURL.length);
                kioskURL = `https://${
                    kioskURL.split('/')[2]
                }/ExistingMerchantExternalWeb/faces/jsf/MerchantLogin.jsp?${idpId}`;
            } else {
                kioskURL = `https://${kioskURL.split('/')[2]}/ExistingMerchantExternalWeb/faces/jsf/Welcome.jsp`;
            }
            dispatch(setType(ESessionTypes.KIOSK));
            dispatch(setKioskURL(kioskURL));
        }
    }, [kioskToken]);

    return { currentSessionId, encodedIncreditToken };
};
