import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { ON_HARD_RESET_EVENT } from 'store/redux/actions';

import { EInitialAuthType } from '../types';

import { getOrCreateSessionAsync } from './actions';
import { ERequestType, ESessionTypes, IContactInfo, ISessionInfo, ISessionResponse, ISessionState } from './types';

export const initialSession: ISessionInfo = {
    channelId: '',
    credentials: {
        accessKeyId: '',
        expiration: '',
        secretAccessKey: '',
        sessionToken: '',
    },
    inCreditToken: {
        cancelUrl: '',
        exp: 0,
        issuer: '',
        region: '',
        returnDomainUrl: '',
        sessionId: '',
        storeId: '',
        transactionAmount: 0,
    },
    kioskUrl: '',
    sessionId: '',
    type: '',
};

export const initialContactInfo = {
    en: {
        openHours: [
            { days: 'Monday to Friday', hours: '8:30 am to 11:00 pm' },
            { days: 'Saturday', hours: '8:00 am to 8:00 pm' },
            { days: 'Sunday', hours: '11:00 am to 8:00 pm' },
        ],
        phone: '866-331-0633',
    },
    fr: {
        openHours: [
            {
                days: 'Lundi au Vendredi',
                hours: '8h30 à 23h00',
            },
            { days: 'Samedi', hours: '8h00 à 20h00' },
            { days: 'Dimanche', hours: '11h00 à 20h00' },
        ],
        phone: '866-331-0633',
    },
};

export const initialState: ISessionState = {
    contactInfo: initialContactInfo,
    documentLink: '',
    error: false,
    initialAuthenticationType: EInitialAuthType.MANUAL,
    loading: false,
    requestType: ERequestType.NONE,
    session: initialSession,
};

export const sessionSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(
            getOrCreateSessionAsync.fulfilled,
            (state: ISessionState, action: PayloadAction<ISessionResponse>) => {
                const { channelId, credentials, enContactUs, frContactUs, inCreditToken, sessionId } = action.payload;

                state.loading = false;
                state.error = false;

                if (!isEmpty(inCreditToken)) {
                    state.session.inCreditToken = inCreditToken;
                    state.session.type = ESessionTypes.ECOM;
                }

                // TODO: Update state session
                state.session.sessionId = sessionId;
                state.session.channelId = channelId;
                state.session.credentials = credentials;

                if (enContactUs.length > 2) {
                    state.contactInfo['en'] = JSON.parse(enContactUs) as IContactInfo;
                }

                if (frContactUs.length > 2) {
                    state.contactInfo['fr'] = JSON.parse(frContactUs) as IContactInfo;
                }
            }
        );
        builder.addCase(getOrCreateSessionAsync.rejected, (state: ISessionState) => {
            state.error = true;
            state.loading = false;
        });
        builder.addCase(getOrCreateSessionAsync.pending, (state: ISessionState) => {
            state.loading = true;
        });
        builder.addCase(ON_HARD_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'session',
    reducers: {
        setDocumentLink(state, action: PayloadAction<string>) {
            state.documentLink = action.payload;
        },
        setInitialAuthType(state, action: PayloadAction<EInitialAuthType>) {
            state.initialAuthenticationType = action.payload;
        },
        setKioskURL(state, action: PayloadAction<string>) {
            state.session.kioskUrl = action.payload;
        },
        setRequestType(state, action: PayloadAction<ERequestType>) {
            state.requestType = action.payload;
        },
        setType(state, action: PayloadAction<string>) {
            state.session.type = action.payload;
        },
    },
});
