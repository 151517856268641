import React from 'react';

interface ILockProps {
    className?: string;
}

export const LockIcon: React.FC<ILockProps> = ({ className = '' }) => (
    <svg className={className} fill="none" height="12" viewBox="0 0 9 12" width="9" xmlns="http://www.w3.org/2000/svg">
        <title>LockIcon</title>
        <path
            clipRule="evenodd"
            d="M2.55052 3.561C2.55052 2.45635 3.43902 1.56003 4.52875 1.56003C5.62085 1.56003 6.50687 2.45872 6.50687 3.561V4.79879H2.55052V3.561ZM5.05078 8.55592C4.97896 8.60925 4.9598 8.66012 4.9598 8.74493C4.96226 9.12519 4.96226 9.50312 4.96226 9.88337C4.96938 10.041 4.89032 10.1959 4.75142 10.2661C4.42341 10.4358 4.09528 10.1984 4.09528 9.88337V9.88101C4.09528 9.50075 4.09528 9.12034 4.09761 8.74009C4.09761 8.66248 4.08088 8.61161 4.01376 8.56077C3.67372 8.30645 3.56122 7.87283 3.73126 7.49255C3.89647 7.12186 4.30121 6.90387 4.68194 6.98136C5.10586 7.06862 5.4004 7.41261 5.40522 7.84137C5.40522 8.13933 5.28793 8.38157 5.05078 8.55592V8.55592ZM1.0107 3.561V4.79879H0.929185C0.416769 4.79879 0 5.22023 0 5.73869V10.9154C0 11.4338 0.416769 11.8553 0.929185 11.8553H8.07082C8.58323 11.8553 9 11.4338 9 10.9154V5.7363C9 5.21787 8.58323 4.79631 8.07082 4.79631H8.04684V3.55852C8.04684 1.59634 6.46852 0 4.52875 0C2.58887 0.00236185 1.0107 1.59882 1.0107 3.561V3.561Z"
            fill="#999999"
            fillRule="evenodd"
        />
    </svg>
);
