import { Signer } from 'aws-amplify';

import { REGION } from 'config/constants';

import { getCredentials } from './tokens';

export const signRequest = async (
    url: string,
    method: string,
    service: string,
    headers: Record<string, unknown>,
    data: Record<string, unknown> | string = ''
): Promise<any> => {
    const { accessKeyId, secretAccessKey, sessionToken } = await getCredentials();
    const params = { data: JSON.stringify(data), headers, method: method.toUpperCase(), url };
    const credentials = {
        access_key: accessKeyId,
        secret_key: secretAccessKey,
        session_token: sessionToken,
    };

    // set your region and service here
    const serviceInfo = { region: REGION, service };
    // Signer.sign takes care of all other steps of Signature V4
    const signedUrl = await Signer.sign(params, credentials, serviceInfo);
    delete signedUrl.headers['host'];

    return signedUrl;
};

export const isUrlIp = (): boolean => !!window.location.hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/);
