export interface IOnfidoState {
    loading: boolean;
    error: boolean;
    status: number | null;
    isManualUpload: boolean;
}

export enum EIOTOnfidoType {
    ONFIDO = 'onfido',
}

export interface IIOTOnfidoResponse {
    reportResponse: number;
    type: EIOTOnfidoType;
}
