import React from 'react';
import { TableRow as MuiTableRow, TableRowProps } from '@fairstone/ui/core';

import styles from './TableRow.module.scss';

export const TableRow = ({ children, ...rest }: TableRowProps): React.ReactElement => (
    <MuiTableRow className={styles.tableRow} {...rest}>
        {children}
    </MuiTableRow>
);
