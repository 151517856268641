import { EStepName } from 'store/redux/modules/types';

export const backButtonBlockedList: EStepName[] = [
    EStepName.ONFIDO_WAITING,
    EStepName.ONFIDO_VALIDATE,
    EStepName.ONFIDO_APPROVED,
    EStepName.DECLINED,
    EStepName.DEBT_RATIO,
    EStepName.PENDING,
    EStepName.CANCEL,
    EStepName.DIGITAL_VALIDATE,
    EStepName.DIGITAL_APPROVED,
    EStepName.ECOM_APPROVED,
    EStepName.ECOM_PENDING,
    EStepName.ECOM_DECLINED,
    EStepName.ECOM_INSUFFICIENT,
    EStepName.ECOM_IDENTIFICATION,
    EStepName.SESSION_FAILURE,
    EStepName.VERIFICATION_FAILURE,
];
