import { RootState } from 'store/redux/types';

import { sessionSlice } from './reducers';
import { ISessionState } from './types';
export { getOrCreateSessionAsync } from './actions';

export const sessionState = (state: RootState): ISessionState => state.session;

export const { setDocumentLink, setInitialAuthType, setKioskURL, setRequestType, setType } = sessionSlice.actions;

export default sessionSlice.reducer;
