import { EnvEnum } from '@fairstone-frontend/utils/core/types';

import { isUrlIp } from 'utils/auth/url';
import { getLocaleFromLocalStorage } from 'utils/language/getLocaleFromLocalStorage';

export const THEME_API_URL = process.env.REACT_APP_THEME_API_URL || '';
export const CANADA_POST_KEY = process.env.REACT_APP_CANADA_POST_KEY || '';
export const API_URL = process.env.REACT_APP_API_URL || '';
export const FLINKS_URL = process.env.REACT_APP_FLINKS_URL || '';
export const PUBLIC = process.env.PUBLIC_URL || '';
export const GTM_ID = process.env.REACT_APP_GTM_ID || '';

export const ONFIDO_TIMER = process.env.REACT_APP_ONFIDO_TIMER || '';
export const ONFIDO_MANUAL_UPLOAD = !!process.env.REACT_APP_ONFIDO_MANUAL_UPLOAD || false;

export const REGION = process.env.REACT_APP_CURRENT_REGION || 'us-east-1';

export const LOGROCKET_APPNAME = process.env.REACT_APP_LOGROCKET_APPNAME || '';
export const TRACKJS_APPNAME = process.env.REACT_APP_TRACKJS_APPNAME || '';
export const TRACKJS_KEY = process.env.REACT_APP_TRACKJS_KEY || '';
export const MERCHANT_CONFIG_API = THEME_API_URL;

export const MERCHANT_IMAGES_URL = `${MERCHANT_CONFIG_API}/${REGION}/images`;

export const COGNITO_IDENTITY_POOL = process.env.REACT_APP_COGNITO_IDENTITY_POOL || '';
export const IOT_ID = process.env.REACT_APP_IOT_ID || '';

export const CURRENT_ENV = process.env.REACT_APP_CURRENT_ENV
    ? (process.env.REACT_APP_CURRENT_ENV as EnvEnum)
    : EnvEnum.LOCAL;
export const SESSION_LOGOUT_TIMER = 1;
export const SESSION_POPUP_TIMER = 5;
export const ROAM_URL = process.env.REACT_APP_ROAM_URL || '';

export const TMX_ORG_ID = process.env.REACT_APP_TMX_ORG_ID || '';
export const SHOW_FLINKS_CAPITAL_BANK = !!process.env.REACT_APP_SHOW_FLINKS_CAPITAL_BANK;
export const DEFAULT_LANGUAGE = window.location.href.includes('/fr') ? 'fr' : getLocaleFromLocalStorage();
export const provincesWithLegalAge = {
    AB: 18,
    BC: 19,
    MB: 18,
    NB: 19,
    NL: 19,
    NS: 19,
    NT: 19,
    NU: 19,
    ON: 18,
    PE: 18,
    QC: 18,
    SK: 18,
    YT: 19,
};

export const EVENTBRIDGE_API_URL = process.env.REACT_APP_EVENTBRIDGE_API_URL || '';
export const EVENTBRIDGE_API_KEY = process.env.REACT_APP_EVENTBRIDGE_API_KEY || '';
export const PAGEVIEWS_TABLE = process.env.REACT_APP_PAGEVIEWS_TABLE || '';
export const DOMAIN = (isUrlIp() ? 'localhost' : process.env.DOMAIN) || '';

export const FEATURE_PROVIDER = process.env.REACT_APP_FEATURE_PROVIDER
    ? JSON.parse(process.env.REACT_APP_FEATURE_PROVIDER)
    : {};
