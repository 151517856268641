export const getLastPath = (url: string): string => {
    // trim spaces
    const trimmedUrl = url.trim();

    // base case
    if (trimmedUrl === '/') return trimmedUrl;

    // Split the URL by '/'
    const urlParts = url.trim().split('/');

    // Get the last part of the URL (ignoring any trailing slashes)
    let lastPath = urlParts[urlParts.length - 1];
    while (lastPath === '') {
        urlParts.pop();
        lastPath = urlParts[urlParts.length - 1];
    }

    return lastPath;
};
