import storage from 'redux-persist/lib/storage/session';

export const rootPersistConfig = {
    blacklist: ['session', 'application', 'merchant'],
    key: 'root',
    storage,
    version: 1.4,
};

export const sessionPersistConfig = {
    blacklist: ['error', 'loading'],
    key: 'session',
    storage: storage,
};

export const applicationPersistConfig = {
    blacklist: ['isAppFlow'],
    key: 'application',
    storage: storage,
};

export const merchantPersistConfig = {
    blacklist: ['error', 'loading'],
    key: 'merchant',
    storage: storage,
};
