import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { HourTable } from 'components/HourTable';

import styles from './ChatCentricErrorScreen.module.scss';

export const ChatCentricErrorScreen: React.FC = () => (
    <div className={styles.container}>
        <Typography className={styles.title} variant="h1">
            {t('pages.errors.500.chatCentricTitle')}
        </Typography>
        <Typography className={styles.subtitle} variant="h2">
            {t('pages.errors.500.subtitle')}
        </Typography>
        <HourTable />
        <Typography className={styles.toptext} variant="body2-semibold">
            {t('pages.errors.500.chatCentricInfo1')}
        </Typography>
        <div data-testid="gliachat" id="gliachat">
            <Typography variant="h3">{t('pages.errors.500.chatCentricInfo2')}</Typography>
        </div>
    </div>
);
