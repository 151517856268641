import React from 'react';
import lazyWithPreload from 'react-lazy-with-preload';
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';

const ApplicationApprovedPage = lazyWithPreload(() => import('pages/Application/Approved'));
const ApplicationDeclinedPage = lazyWithPreload(() => import('pages/Application/Declined'));
const ApplicationPendingPage = lazyWithPreload(() => import('pages/Application/Pending'));
const ApplicationCancelPage = lazyWithPreload(() => import('pages/Application/Cancel'));

const CustomerAddressPage = lazyWithPreload(() => import('pages/Customer/Address'));
const CustomerHousingPage = lazyWithPreload(() => import('pages/Customer/Housing'));
const CustomerIdentificationPage = lazyWithPreload(() => import('pages/Customer/Identification'));
const CustomerInformationPage = lazyWithPreload(() => import('pages/Customer/Information'));

const EcomApprovedPage = lazyWithPreload(() => import('pages/Ecom/Approved'));
const EcomDeclinedPage = lazyWithPreload(() => import('pages/Ecom/Declined'));
const EcomPendingPage = lazyWithPreload(() => import('pages/Ecom/Pending'));
const EcomInsufficientFundPage = lazyWithPreload(() => import('pages/Ecom/InsufficientFund'));
const EcomIdentificationPage = lazyWithPreload(() => import('pages/Ecom/Identification'));
const EcomErrorPage = lazyWithPreload(() => import('pages/Ecom/Error'));

const DebtRatioPage = lazyWithPreload(() => import('pages/DebtRatio'));

const ErrorPage = lazyWithPreload(() => import('pages/Error'));
const SessionFailurePage = lazyWithPreload(() => import('pages/SessionFailure'));
const VerificationFailurePage = lazyWithPreload(() => import('pages/VerificationFailure'));
const NotFoundPage = lazyWithPreload(() => import('pages/NotFound'));

const FlinksIframePage = lazyWithPreload(() => import('pages/Flinks/Iframe'));
const FlinksDisclaimerPage = lazyWithPreload(() => import('pages/Flinks/Disclaimer'));
const FlinksAddressPage = lazyWithPreload(() => import('pages/Flinks/Address'));
const FlinksInformationPage = lazyWithPreload(() => import('pages/Flinks/Information'));
const FlinksManualPage = lazyWithPreload(() => import('pages/Flinks/Manual'));
const FlinksErrorPage = lazyWithPreload(() => import('pages/Flinks/Error'));
const FlinksApprovedPage = lazyWithPreload(() => import('pages/Digital/Approved'));

const OnfidoVerifyPage = lazyWithPreload(() => import('pages/Onfido/Verify'));
const OnfidoDisclaimerPage = lazyWithPreload(() => import('pages/Onfido/Disclaimer'));
const OnfidoInvalidDocumentPage = lazyWithPreload(() => import('pages/Onfido/InvalidDocument'));
const OnfidoManualPage = lazyWithPreload(() => import('pages/Onfido/Manual'));
const WaitingPage = lazyWithPreload(() => import('pages/Onfido/Waiting'));
const OnfidoOCRConfirmationPage = lazyWithPreload(() => import('pages/Onfido/OCRConfirmation'));
const OnfidoApprovedPage = lazyWithPreload(() => import('pages/Digital/Approved'));

const OnfidoInformationPage = lazyWithPreload(() => import('pages/Onfido/Information'));
const OnfidoAddressPage = lazyWithPreload(() => import('pages/Onfido/Address'));
const OnfidoHousingPage = lazyWithPreload(() => import('pages/Onfido/Housing'));

const TermsPage = lazyWithPreload(() => import('pages/Terms'));
const MaintenancePage = lazyWithPreload(() => import('pages/Maintenance'));

const PathDecisionPage = lazyWithPreload(() => import('pages/PathDecision'));
const PrivacyBillPage = lazyWithPreload(() => import('pages/PrivacyBill'));

const LanguageSelectionPage = lazyWithPreload(() => import('pages/LanguageSelection'));

import { SplashPage } from 'pages/Splash';

import RouteAppLayout from './RouteAppLayout';

export const routes: RouteObject[] = [
    {
        children: [
            {
                element: <MaintenancePage />,
                path: 'maintenance',
            },
            {
                children: [
                    {
                        element: <SplashPage />,
                        index: true,
                        loader: () => LanguageSelectionPage.preload(),
                    },
                    {
                        element: <SplashPage />,
                        loader: () => LanguageSelectionPage.preload(),
                        path: 'fr',
                    },
                    {
                        element: <LanguageSelectionPage />,
                        loader: () => PathDecisionPage.preload(),
                        path: 'lang-select',
                    },
                    {
                        element: <PathDecisionPage />,
                        loader: (): null => {
                            OnfidoDisclaimerPage.preload();
                            FlinksDisclaimerPage.preload();
                            CustomerInformationPage.preload();
                            return null;
                        },
                        path: 'path-decision',
                    },
                    {
                        children: [
                            {
                                children: [
                                    {
                                        element: <EcomApprovedPage />,
                                        path: 'approved',
                                    },
                                    {
                                        element: <EcomDeclinedPage />,
                                        path: 'declined',
                                    },
                                    {
                                        element: <EcomPendingPage />,
                                        path: 'pending',
                                    },
                                    {
                                        element: <EcomIdentificationPage />,
                                        path: 'identification',
                                    },
                                    {
                                        element: <EcomInsufficientFundPage />,
                                        path: 'insufficient',
                                    },
                                ],
                                path: 'second-source',
                            },
                            {
                                element: <EcomApprovedPage />,
                                path: 'approved',
                            },
                            {
                                element: <EcomDeclinedPage />,
                                path: 'declined',
                            },
                            {
                                element: <EcomPendingPage />,
                                path: 'pending',
                            },
                            {
                                element: <EcomErrorPage />,
                                path: 'error',
                            },
                            {
                                element: <EcomIdentificationPage />,
                                path: 'identification',
                            },
                            {
                                element: <EcomInsufficientFundPage />,
                                path: 'insufficient',
                            },
                        ],
                        path: 'ecom',
                    },
                    {
                        children: [
                            {
                                element: <OnfidoDisclaimerPage />,
                                loader: () => OnfidoVerifyPage.preload(),
                                path: 'disclaimer',
                            },
                            {
                                element: <OnfidoInvalidDocumentPage />,
                                path: 'invalid-document',
                            },
                            {
                                element: <OnfidoInformationPage />,
                                loader: () => OnfidoAddressPage.preload(),
                                path: 'tell-us-about-you',
                            },
                            {
                                element: <OnfidoAddressPage />,
                                loader: () => OnfidoHousingPage.preload(),
                                path: 'address',
                            },
                            {
                                element: <OnfidoHousingPage />,
                                loader: () => PrivacyBillPage.preload(),
                                path: 'housing',
                            },
                            {
                                element: <PrivacyBillPage />,
                                loader: () => TermsPage.preload(),
                                path: 'privacy-bill',
                            },
                            {
                                element: <TermsPage />,
                                loader: (): null => {
                                    WaitingPage.preload();
                                    OnfidoApprovedPage.preload();
                                    ApplicationDeclinedPage.preload();
                                    ApplicationPendingPage.preload();
                                    ApplicationCancelPage.preload();

                                    return null;
                                },
                                path: 'terms-and-conditions',
                            },
                            {
                                element: <OnfidoOCRConfirmationPage />,
                                loader: () => OnfidoInformationPage.preload(),
                                path: 'confirmation',
                            },
                            {
                                element: <OnfidoVerifyPage />,
                                loader: (): null => {
                                    OnfidoOCRConfirmationPage.preload();
                                    OnfidoManualPage.preload();
                                    OnfidoInvalidDocumentPage.preload();

                                    return null;
                                },
                                path: 'verify',
                            },
                            {
                                element: <OnfidoManualPage />,
                                path: 'validate',
                            },
                            {
                                element: <WaitingPage />,
                                path: 'waiting',
                            },
                            {
                                element: <OnfidoApprovedPage />,
                                path: 'approved',
                            },
                            {
                                children: [
                                    {
                                        element: <OnfidoApprovedPage />,
                                        path: 'approved',
                                    },
                                ],
                                path: 'second-source',
                            },
                        ],
                        path: 'onfido',
                    },
                    {
                        children: [
                            {
                                element: <FlinksDisclaimerPage />,
                                loader: () => FlinksIframePage.preload(),
                                path: 'disclaimer',
                            },
                            {
                                element: <FlinksInformationPage />,
                                loader: () => FlinksAddressPage.preload(),
                                path: 'tell-us-about-you',
                            },
                            {
                                element: <FlinksAddressPage />,
                                loader: () => CustomerHousingPage.preload(),
                                path: 'address',
                            },
                            {
                                element: <CustomerHousingPage />,
                                loader: () => PrivacyBillPage.preload(),
                                path: 'housing',
                            },
                            {
                                element: <PrivacyBillPage />,
                                loader: () => TermsPage.preload(),
                                path: 'privacy-bill',
                            },
                            {
                                element: <TermsPage />,
                                loader: (): null => {
                                    FlinksManualPage.preload();
                                    FlinksApprovedPage.preload();
                                    ApplicationDeclinedPage.preload();
                                    ApplicationPendingPage.preload();
                                    ApplicationCancelPage.preload();

                                    return null;
                                },
                                path: 'terms-and-conditions',
                            },
                            {
                                element: <FlinksIframePage />,
                                loader: () => FlinksInformationPage.preload(),
                                path: 'verify',
                            },
                            {
                                element: <FlinksManualPage />,
                                path: 'validate',
                            },
                            {
                                element: <FlinksErrorPage />,
                                path: 'error',
                            },
                            {
                                element: <FlinksApprovedPage />,
                                path: 'approved',
                            },
                            {
                                children: [
                                    {
                                        element: <FlinksApprovedPage />,
                                        path: 'approved',
                                    },
                                ],
                                path: 'second-source',
                            },
                        ],
                        path: 'digital',
                    },
                    {
                        element: <CustomerIdentificationPage />,
                        path: 'identification',
                    },
                    {
                        element: <CustomerInformationPage />,
                        loader: () => CustomerAddressPage.preload(),
                        path: 'tell-us-about-you',
                    },
                    {
                        element: <CustomerAddressPage />,
                        loader: () => CustomerHousingPage.preload(),
                        path: 'address',
                    },
                    {
                        element: <CustomerHousingPage />,
                        loader: () => PrivacyBillPage.preload(),
                        path: 'housing',
                    },
                    {
                        element: <PrivacyBillPage />,
                        loader: () => TermsPage.preload(),
                        path: 'privacy-bill',
                    },
                    {
                        element: <TermsPage />,
                        loader: (): null => {
                            ApplicationApprovedPage.preload();
                            ApplicationDeclinedPage.preload();
                            ApplicationPendingPage.preload();
                            ApplicationCancelPage.preload();

                            return null;
                        },

                        path: 'terms-and-conditions',
                    },
                    {
                        element: <ApplicationCancelPage />,
                        path: 'cancel',
                    },
                    {
                        element: <ApplicationPendingPage />,
                        path: 'pending',
                    },
                    {
                        element: <ApplicationApprovedPage />,
                        loader: () => CustomerIdentificationPage.preload(),
                        path: 'approved',
                    },
                    {
                        children: [
                            {
                                element: <ApplicationApprovedPage />,
                                loader: () => CustomerIdentificationPage.preload(),
                                path: 'approved',
                            },
                            {
                                element: <ApplicationPendingPage />,
                                path: 'pending',
                            },
                            {
                                element: <ApplicationDeclinedPage />,
                                path: 'declined',
                            },
                        ],
                        path: 'second-source',
                    },
                    {
                        element: <ApplicationDeclinedPage />,
                        path: 'declined',
                    },
                    {
                        element: <DebtRatioPage />,
                        path: 'debt-ratio',
                    },
                    {
                        element: <ErrorPage />,
                        path: 'error',
                    },
                    {
                        element: <SessionFailurePage />,
                        path: 'session-failure',
                    },
                    {
                        element: <VerificationFailurePage />,
                        path: 'verification-failure',
                    },
                    {
                        element: <NotFoundPage />,
                        path: '404',
                    },
                ],
                path: ':merchant',
            },
            {
                element: <NotFoundPage />,
                path: '404',
            },
            {
                element: <NotFoundPage />,
                path: '*',
            },
        ],
        element: (
            <RouteAppLayout>
                <Outlet />
            </RouteAppLayout>
        ),
    },
];

export const router = createBrowserRouter(routes);
