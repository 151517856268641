import React, { SyntheticEvent } from 'react';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import InputMask, { BeforeMaskedStateChangeStates, InputState } from 'react-input-mask';
import { Autocomplete, AutocompleteRenderInputParams, icons, MenuItem } from '@fairstone/ui/core';
import { TextField } from '@fairstone/ui/core/components/TextField';
import { CustomTextFieldProps } from '@fairstone/ui/core/components/TextField/TextField';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { getValidAdornmentIcon } from 'utils/validation/validations';

import styles from './ControlledTextField.module.scss';

export type TControlledTextFieldProps = CustomTextFieldProps & {
    name: string;
    control: Control<any>;
    setValue?: UseFormSetValue<FieldValues>;
    format?: string;
    options?: {
        label: string;
        value: string;
    }[];
    submitNumeric?: boolean;
    InputPropsCustom?: any;
    hasCheckIcon?: boolean;
    typeableSelect?: boolean;
    mask?: string | (string | RegExp)[];
    beforeMaskedStateChange?: (states: BeforeMaskedStateChangeStates) => InputState;
};

type CustomChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const ListboxComponent = React.forwardRef(
    (props: React.HTMLAttributes<HTMLElement>, ref: React.LegacyRef<HTMLUListElement>) => (
        <ul {...props} className={styles.MuiAutocompleteListbox} ref={ref} />
    )
);

export const ControlledTextField: React.FC<TControlledTextFieldProps> = ({
    beforeMaskedStateChange,
    className = '',
    control,
    hasCheckIcon = true,
    helperText,
    InputProps,
    InputPropsCustom,
    label,
    mask,
    name,
    onChange: customOnChange,
    options,
    placeholder,
    SelectProps,
    typeableSelect,
    ...rest
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, ref, value }, fieldState: { error, invalid } }) => {
            const showValidation = hasCheckIcon && !invalid;
            if (mask) {
                return (
                    <InputMask
                        beforeMaskedStateChange={beforeMaskedStateChange}
                        mask={mask}
                        maskPlaceholder={null}
                        onBlur={onBlur}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            onChange(event);
                            customOnChange?.(event);
                        }}
                        value={value}
                    >
                        <TextField
                            InputProps={{
                                ...InputProps,
                                endAdornment: getValidAdornmentIcon(value, showValidation, invalid, styles),
                            }}
                            SelectProps={{
                                ...SelectProps,
                                IconComponent: () => (showValidation ? null : <icons.ArrowDropDown />),
                            }}
                            appearance="new"
                            className={cx(styles.textField, className)}
                            error={invalid}
                            helperText={error?.message ? t(error.message) : helperText}
                            inputRef={ref}
                            label={label}
                            name={name}
                            placeholder={placeholder}
                            {...rest}
                        />
                    </InputMask>
                );
            }

            return (
                <>
                    {typeableSelect && options ? (
                        <Autocomplete
                            ListboxComponent={ListboxComponent}
                            autoHighlight
                            autoSelect
                            disableClearable
                            getOptionLabel={(item) => (item.label ? item.label : '')}
                            onChange={(event: SyntheticEvent<Element, Event>, value) => {
                                onChange(value.value);
                                customOnChange?.(event as CustomChangeEvent);
                            }}
                            options={options}
                            renderInput={({
                                InputProps: autocompleteInputProps,
                                ...params
                            }: AutocompleteRenderInputParams) => (
                                <TextField
                                    InputProps={{
                                        ...autocompleteInputProps,
                                        ...InputPropsCustom,
                                        endAdornment: (
                                            <>
                                                {getValidAdornmentIcon(value, showValidation, invalid, styles)}
                                                {autocompleteInputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                    SelectProps={{
                                        ...SelectProps,
                                        IconComponent: () => (showValidation ? null : <icons.ArrowDropDown />),
                                    }}
                                    appearance="new"
                                    className={cx(styles.textField, className)}
                                    error={invalid}
                                    helperText={error?.message ? t(error.message) : helperText}
                                    inputRef={ref}
                                    label={label}
                                    name={name}
                                    placeholder={placeholder}
                                    value={value}
                                    {...params}
                                />
                            )}
                            renderOption={({ className, ...rest }, option) => (
                                <MenuItem
                                    className={cx(className, styles.MuiMenuItemRoot)}
                                    key={option.value}
                                    value={option.value}
                                    {...rest}
                                >
                                    {option.label}
                                </MenuItem>
                            )}
                            sx={
                                invalid
                                    ? {
                                          '& .MuiAutocomplete-inputRoot': {
                                              '& .MuiButtonBase-root': {
                                                  marginRight: '30px!important',
                                              },
                                              paddingRight: '0px!important',
                                          },
                                      }
                                    : {}
                            }
                            value={options.find((option) => option.value === value) || undefined}
                        />
                    ) : (
                        <TextField
                            InputProps={{
                                ...InputProps,
                                endAdornment: getValidAdornmentIcon(value, showValidation, invalid, styles),
                            }}
                            SelectProps={{
                                ...SelectProps,
                                IconComponent: () => (showValidation ? null : <icons.ArrowDropDown />),
                            }}
                            appearance="new"
                            className={cx(styles.textField, className)}
                            error={invalid}
                            helperText={error?.message ? t(error.message) : helperText}
                            inputRef={ref}
                            label={label}
                            name={name}
                            onBlur={onBlur}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                onChange(event);
                                customOnChange?.(event);
                            }}
                            placeholder={placeholder}
                            value={value}
                            {...rest}
                        />
                    )}
                </>
            );
        }}
    />
);
