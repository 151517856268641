import React, { useEffect } from 'react';
import { useAnalyticsServices } from 'hooks/useAnalyticsServices/useAnalyticsServices';

import { useAppSelector } from 'store/redux/hooks';
import { merchantState } from 'store/redux/modules/merchant';
import { EMerchantErrorPgType } from 'store/redux/modules/merchant/types';

import { ChatCentricErrorScreen } from './screens/ChatCentricErrorScreen';
import { ErrorScreen } from './screens/ErrorScreen';

export const ErrorPage = (): React.ReactElement => {
    const {
        config: { errorPageType },
    } = useAppSelector(merchantState);
    const { pushDataLayer } = useAnalyticsServices();

    useEffect(() => {
        pushDataLayer({
            event: 'error',
        });
    }, []);

    return errorPageType === EMerchantErrorPgType.CHAT_CENTRIC ? <ChatCentricErrorScreen /> : <ErrorScreen />;
};
