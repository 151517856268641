import React from 'react';
import { useIntl } from 'react-intl';
import { Divider } from '@fairstone/ui/core';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { FairstoneReversed } from 'components/Icon/FairstoneReversed';
import { LockIcon } from 'components/Icon/LockIcon';

import styles from './Footer.module.scss';

interface IFooter {
    className?: string;
}
export const Footer: React.FC<IFooter> = ({ className }) => {
    const intl = useIntl();
    return (
        <div className={cx(styles.footer, className)}>
            <div className={styles.top}>
                <FairstoneReversed />
                <Divider className={styles.divider} flexItem orientation="vertical" variant="middle" />
                <Typography className={styles.provided} variant="body4">
                    {t('components.footer.provided')}
                </Typography>
            </div>
            <div className={styles.bottom}>
                <div>
                    <LockIcon className={styles.lockIcon} />
                    <Typography variant="body4">{t('components.footer.headline')}</Typography>
                </div>
                <Link
                    className={styles.privacy}
                    href={intl.formatMessage({ id: 'components.footer.link' })}
                    target="_blank"
                >
                    {t('components.footer.policy')}
                </Link>
            </div>
        </div>
    );
};
