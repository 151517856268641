import React, { useState } from 'react';
import { AlertColor, AlertProps, IconButton } from '@fairstone/ui/core';
import { Alert as MUIAlert } from '@fairstone/ui/core/components';
import { translate } from '@fairstone/ui/core/utils/translate';
import CloseIcon from '@mui/icons-material/Close';
import cx from 'classnames';

export interface IAlertInfo {
    severity: AlertColor;
    message: string | React.ReactElement;
}

import styles from './Alert.module.scss';
interface IAlertProps extends AlertProps {
    info: IAlertInfo;
    lightBackground?: boolean;
}

export const Alert: React.FC<IAlertProps> = ({ info, lightBackground = true, variant = 'outlined' }) => {
    const [show, setShow] = useState<boolean>(true);
    return (
        show && (
            <MUIAlert
                action={
                    <IconButton
                        aria-label={translate('global.close')}
                        color="inherit"
                        data-testid="alert-close-button"
                        id={styles.iconBtn}
                        onClick={() => setShow(false)}
                    >
                        <CloseIcon className={styles.icon} fontSize="inherit" />
                    </IconButton>
                }
                className={cx(styles.alert)}
                id={lightBackground ? styles.lightBackground : ''}
                severity={info.severity}
                variant={variant}
            >
                <div className={styles.message} tabIndex={0}>
                    {info.message}
                </div>
            </MUIAlert>
        )
    );
};
