import React from 'react';
import { useIntl } from 'react-intl';
import { SessionManagerProvider } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { t } from '@fairstone/ui/core/utils/translate';

import { SESSION_LOGOUT_TIMER, SESSION_POPUP_TIMER } from 'config/constants';
import { CountdownDisplay } from 'providers/Session/CountdownDisplay';
import { resetStore } from 'store/redux/actions';
import { useAppDispatch } from 'store/redux/hooks';

export const SessionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { locale } = useIntl();
    const dispatch = useAppDispatch();
    return (
        <SessionManagerProvider
            activitateSessionTimeout={false}
            onFinishTimeout={() => dispatch(resetStore(locale))}
            sessionTimeoutLogout={SESSION_LOGOUT_TIMER}
            sessionTimeoutPopup={SESSION_POPUP_TIMER}
            text={{
                action: t('pages.session.action'),
                title: <CountdownDisplay />,
            }}
        >
            {children}
        </SessionManagerProvider>
    );
};
