import { RootState } from 'store/redux/types';

import { flinksSlice } from './reducers';
import { IFlinksState } from './types';

export const flinksState = (state: RootState): IFlinksState => state.flinks;

export { checkServiceAsync } from './actions';
export const { setConsentTimestamp, setErrorMessage, setUpdated } = flinksSlice.actions;

export default flinksSlice.reducer;
