import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Button } from '@fairstone/ui/core/components/Button';
import { Dialog } from '@fairstone/ui/core/components/Dialog';
import { AlertDialog } from '@fairstone/ui/core/components/Dialog/AlertDialog';
import { DialogActions } from '@fairstone/ui/core/components/Dialog/DialogActions';
import { DialogContent } from '@fairstone/ui/core/components/Dialog/DialogContent';
import { DialogTitle } from '@fairstone/ui/core/components/Dialog/DialogTitle';
import { Radio } from '@fairstone/ui/core/components/Radio';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { ControlledTextField as TextField } from 'components/ControlledTextField';
import { Approved } from 'components/Icon/Approved';
import { DigitalCredit } from 'components/Icon/DigitalCredit';
import { Secure } from 'components/Icon/Secure';
import { RadioGroup } from 'components/RadioGroup';

import { TSalesTracking } from '../SalesTracking.validation';

import styles from './SplashScreen.module.scss';

interface ISplashScreenProps {
    applyHandler: (isSalesTrackingId?: string) => Promise<void>;
    formOptions: UseFormReturn<TSalesTracking>;
    isSalesAssociateTracking: boolean;
    kioskDialog: boolean;
    salesTrackingDialog: boolean;
    handleOK: () => void;
    handleOpenSalesTrackingDialog: () => void;
    onClickMyAccount: () => void;
    handleOnSubmitSalesTracking: (data: TSalesTracking) => void;
}

export const SplashScreen: React.FC<ISplashScreenProps> = ({
    applyHandler,
    formOptions,
    handleOK,
    handleOnSubmitSalesTracking,
    handleOpenSalesTrackingDialog,
    isSalesAssociateTracking,
    kioskDialog,
    onClickMyAccount,
    salesTrackingDialog,
}): React.ReactElement => {
    const {
        control,
        formState: { isValid },
        watch,
    } = formOptions;

    const assistance = watch('assistance');

    return (
        <div className={styles.home}>
            <Typography className={styles.title} variant="h1">
                {t('pages.splash.title')}
            </Typography>
            <div className={styles.infoWrapper} data-testid="icons">
                <div>
                    <Secure className={styles.icon} />
                    <Typography variant="body2-semibold"> {t('pages.splash.icons.secure')}</Typography>
                </div>
                <div>
                    <Approved className={styles.icon} />
                    <Typography variant="body2-semibold">{t('pages.splash.icons.approved')}</Typography>
                </div>
                <div>
                    <DigitalCredit className={styles.icon} />
                    <Typography variant="body2-semibold">{t('pages.splash.icons.digitalCredit')}</Typography>
                </div>
            </div>
            <div className={styles.actions}>
                <Button
                    appearance="contained"
                    border
                    data-testid="startButton"
                    onClick={async () => {
                        if (isSalesAssociateTracking) {
                            handleOpenSalesTrackingDialog();
                        } else {
                            await applyHandler();
                        }
                    }}
                    size="large"
                >
                    {t('pages.splash.buttons.getStarted')}
                </Button>
                <div className={styles.account}>
                    <Typography variant="body2">{t('pages.splash.buttons.accountExist')}</Typography>

                    <Button
                        appearance="text"
                        className={styles.textButon}
                        data-testid="manual-address"
                        onClick={onClickMyAccount}
                        size="large"
                    >
                        {t('pages.splash.buttons.signIn')}
                    </Button>
                </div>
            </div>

            {/* Dialog: Kiosk */}
            <AlertDialog
                actions={
                    <Button appearance="contained" border onClick={handleOK} size="large">
                        {t('pages.splash.dialog.button')}
                    </Button>
                }
                open={kioskDialog}
                variant="warning"
            >
                <Typography className={styles.kioskText} variant="h2">
                    {t('pages.splash.dialog.content')}
                </Typography>
            </AlertDialog>

            {/* Dialog: Sales Tracking */}
            <Dialog open={salesTrackingDialog}>
                <FormProvider {...formOptions}>
                    <form
                        data-testid="customer-housing-form"
                        onSubmit={formOptions.handleSubmit(handleOnSubmitSalesTracking)}
                    >
                        <DialogTitle>{t('pages.splash.salesTrackingDialog.title')}</DialogTitle>
                        <DialogContent>
                            <RadioGroup
                                aria-label="assistance"
                                className={styles.formGroup}
                                control={control}
                                data-testid="assistance"
                                name="assistance"
                            >
                                <Radio
                                    appearance="new"
                                    data-testid="yes"
                                    label={t('pages.splash.salesTrackingDialog.radio.yes')}
                                    size="large"
                                    value="yes"
                                />
                                <Radio
                                    appearance="new"
                                    data-testid="no"
                                    label={t('pages.splash.salesTrackingDialog.radio.no')}
                                    size="large"
                                    value="no"
                                />
                            </RadioGroup>
                            {assistance === 'yes' && (
                                <TextField
                                    className={styles.salesTrackingId}
                                    control={control}
                                    data-testid="trackingId"
                                    fullWidth
                                    label={t('pages.splash.salesTrackingDialog.textfield.label')}
                                    name="trackingId"
                                />
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="contained"
                                aria-label="continue"
                                border
                                data-testid="continue"
                                disabled={!isValid}
                                type="submit"
                            >
                                {t('pages.splash.salesTrackingDialog.button')}
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </div>
    );
};
