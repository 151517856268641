import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { Middleware } from 'redux';

import { hardReset, ON_RESET_EVENT } from 'store/redux/actions';
import { persistedStore } from 'store/redux/store';
import { RootState } from 'store/redux/types';
import { redirect } from 'utils/url/redirect';

export const onResetEventMiddleware: Middleware<Record<string, unknown>, RootState> =
    ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
        if (action.type === ON_RESET_EVENT) {
            const {
                merchant: {
                    config: { merchantContext },
                },
            } = getState();
            const language = action.payload.includes('fr') ? '/fr' : '';
            try {
                await dispatch(hardReset());
                await persistedStore.flush();
            } catch (error: any) {
                TrackJS.track(error);
            }

            redirect(`${merchantContext}${language}`);
            return;
        }
        return next(action);
    };
