import { useCallback } from 'react';
import TagManager from 'react-gtm-module';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useAppSelector } from 'store/redux/hooks';
import { merchantState } from 'store/redux/modules/merchant';
import { sessionState } from 'store/redux/modules/session';
import { getTimestampForGA } from 'utils/format/getTimestampForGA';

import { IGADefaultDataType, TGACustomDataType, TGADataLayer } from './types';

export const GA_STEP_NAME_EVENT = 'step_change';

interface IAnalyticsServicesHook {
    pushDataLayer: (data?: TGACustomDataType | undefined) => void;
    pushSubmitEvent: () => void;
    pushAppCompletionEvent: () => void;
    getVariableFromDataLayer: (variableName: string) => any;
}

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export const useAnalyticsServices = (): IAnalyticsServicesHook => {
    const { merchant: merchantParam } = useParams();
    const {
        config: { merchant, merchantHash },
    } = useAppSelector(merchantState);

    const {
        session: { sessionId },
    } = useAppSelector(sessionState);

    const intl = useIntl();

    const push = (merchant: string, sessionId: string, customData?: TGACustomDataType): void => {
        let data: TGADataLayer | null = null;
        const { pathname } = window.location;
        const merchantName = merchant || merchantParam || '';

        if (pathname) {
            const defaultData: IGADefaultDataType = {
                event: GA_STEP_NAME_EVENT,
                merchant_name: merchantName,
                sessionId: !isEmpty(sessionId) ? sessionId : undefined,
                timestamp: getTimestampForGA(new Date()),
                vp_current_page_name: `${pathname.substring(
                    pathname.lastIndexOf(merchantName) + merchantName.length
                )}/${intl.locale.includes('fr') ? 'FR' : 'EN'} page`,
                vp_current_page_url: pathname,
            };
            data = defaultData;
        }

        /* Braze configuration */
        data = {
            ...data,
            indirect_merchant_id: merchantHash,
        } as TGADataLayer;

        if (customData) {
            data = {
                ...data,
                ...customData,
            };
        }

        if (!isEmpty(data)) {
            TagManager.dataLayer({
                dataLayer: data,
            });
        }
    };

    const getVariableFromDataLayer = (variableName: string): any =>
        window?.dataLayer?.find((entry) => entry.hasOwnProperty(variableName))?.[variableName];

    const pushDataLayer = useCallback(function (data?: TGACustomDataType) {
        push(merchant, sessionId, data);
    }, []);

    const pushSubmitEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'submit',
                merchant_name: merchant,
                sessionId,
                timestamp: getTimestampForGA(new Date()),
            },
        });
    };

    const pushAppCompletionEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'app_completion',
                timestamp: getTimestampForGA(new Date()),
            },
        });
    };

    return { getVariableFromDataLayer, pushAppCompletionEvent, pushDataLayer, pushSubmitEvent };
};
