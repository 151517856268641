import React from 'react';
import { useIntl } from 'react-intl';

import { Fairstone } from 'components/Icon/Fairstone';
import { FairstoneFr } from 'components/Icon/FairstoneFr';
import { MERCHANT_IMAGES_URL } from 'config/constants';
import { useAppSelector } from 'store/redux/hooks';
import { merchantState } from 'store/redux/modules/merchant';

import styles from './Logo.module.scss';
export const Logo = (): React.ReactElement => {
    const { locale } = useIntl();

    const {
        config: { merchant, merchantLogoType },
    } = useAppSelector(merchantState);

    return merchantLogoType === 'custom' ? (
        <img className={styles.merchantLogo} src={`${MERCHANT_IMAGES_URL}/${merchant}/${merchant}_logo_reversed.svg`} />
    ) : locale.includes('en') ? (
        <Fairstone />
    ) : (
        <FairstoneFr />
    );
};
