export enum EMerchantCardType {
    DEFAULT = 'default',
    CUSTOM = 'custom',
}

export enum EMerchantKycType {
    ALL = 'ALL',
    MANUAL = 'MANUAL',
    DIGITALFIRST = 'DIGITALFIRST',
    DIGITALONLY = 'DIGITALONLY',
}

export enum EMerchantMode {
    UPDATE = 'UPDATE',
}

export enum EMerchantErrorPgType {
    CHAT_CENTRIC = 'CHAT-CENTRIC',
    STANDARD = 'STANDARD',
}

export interface IMerchantConfigResponse {
    merchant: string;
    merchantId: string;
    merchantStoreId: string;
    merchantName: string;
    merchantTitleFr: string;
    merchantTitleEn: string;
    merchantCardType: EMerchantCardType;
    merchantContext: string;
    merchantUrl: string;
    merchantPublicUrl: string;
    merchantApiKey: string;
    merchantHash: string;
    merchantCardBackgroundType: string;
    merchantCardBackgroundColor: string;
    merchantCardInvertedText: string;
    merchantLogoType: string;
    kycType: EMerchantKycType;
    contractExpiryDate: string;
    mode: EMerchantMode;
    merchantCardLogo: string;
    merchantLandingPageBackground: string;
    isDigitalEnabledForManualAuthentication: string;
    isSalesAssociateTracking: boolean;
    merchantCommonName: string;
    rcaCode: string;
    errorPageType: EMerchantErrorPgType;
}

export interface IMerchantState {
    error: boolean;
    loading: boolean;
    config: IMerchantConfigResponse;
}
