import { Middleware } from 'redux';

import { ON_IOT_DATA, onApplicationResponse } from 'store/redux/actions';
import { setCreditApplicationData } from 'store/redux/modules/application';
import { EIOTPublishResponseType, IIOTResponse } from 'store/redux/modules/application/types';
import { EIOTFlinksType, IIOTFlinksResponse } from 'store/redux/modules/flinks/types';
import { EIOTOnfidoType, IIOTOnfidoResponse } from 'store/redux/modules/onfido/types';
import { RootState } from 'store/redux/types';
import { formatPhoneNumber } from 'utils/format/formatPhoneNumber';
import { redirect } from 'utils/url/redirect';

import { setCustomerAddress, setCustomerInformation } from '../modules/customer';
import { setUpdated } from '../modules/flinks';
import { setOnfidoStatus } from '../modules/onfido';
import { EInitialAuthType } from '../modules/types';

export const onIOTDataEventMiddleware: Middleware<Record<string, unknown>, RootState> =
    ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
        const { payload, type } = action;
        if (type === ON_IOT_DATA) {
            const {
                application: { submitted },
                merchant: {
                    config: { merchantContext },
                },
            } = getState();

            if (payload?.type === EIOTPublishResponseType.SESSION_FAILED) {
                redirect(`${merchantContext}/session-failure`);
                return;
            }
            // Make sure to redirect to error if you receive a lastResponse Onfido or KYC when you want CreditApplication
            if (submitted && payload.type) {
                redirect(`${merchantContext}/error`);
            }

            if (payload.type && payload.type === EIOTFlinksType.KYC) {
                const iotData = payload as IIOTFlinksResponse;
                const { city, civicAddress, email, name, phoneNumber, postalCode, province } = iotData.kyc;
                const names = name?.split(' ') || [];
                let firstName = '';
                let middleName = '';
                let lastName = '';

                if (names.length > 0) {
                    firstName = names.shift() || '';

                    if (names.length >= 2) {
                        middleName = names.shift() || '';
                        lastName = names.join(' ');
                    } else {
                        lastName = names.pop() || '';
                    }
                }
                const formattedPhoneNumber = formatPhoneNumber(phoneNumber || '');

                await dispatch(
                    setCustomerInformation({
                        email: email || '',
                        firstName,
                        lastName,
                        middleName,
                        phoneNumber: formattedPhoneNumber,
                    })
                );
                await dispatch(
                    setCustomerAddress({
                        city: city || '',
                        cityFlinks: city || '',
                        fullAddress: civicAddress || '',
                        postalCode: postalCode || '',
                        postalCodeFlinks: postalCode || '',
                        province: province || '',
                        provinceFlinks: province || '',
                    })
                );
                await dispatch(setUpdated(true));
            } else if (payload.type && payload.type === EIOTOnfidoType.ONFIDO) {
                const iotData = payload as IIOTOnfidoResponse;
                await dispatch(setOnfidoStatus(iotData.reportResponse));
            } else if (
                payload.finalAuthenticationType &&
                payload.finalAuthenticationType === EInitialAuthType.DIGITAL &&
                payload.mismatchAttribute &&
                payload.mismatchAttribute === true
            ) {
                redirect(`${merchantContext}/digital/validate`);
            } else {
                const iotData = payload as IIOTResponse;
                const {
                    accountNumberNotFormatted,
                    applicationID,
                    applicationIdNotFormated,
                    applicationStatus,
                    creditLimit,
                    finalAuthenticationType,
                    grossMonthlyIncomeAmount,
                    housingPaymentAmount,
                    isSellDown,
                    newPaymentAmount,
                    otherDebtObligationAmount,
                    responseStatus,
                    totalDebtServiceRatioAmount,
                    utilitiesAmount,
                } = iotData;
                await dispatch(
                    setCreditApplicationData({
                        accountNumber: accountNumberNotFormatted ? parseInt(accountNumberNotFormatted) : 0,
                        applicationId: applicationIdNotFormated ? parseInt(applicationIdNotFormated) : 0,
                        applicationIdFormatted: applicationID || '',
                        creditLimit: creditLimit || 0,
                        debtRatio: {
                            grossMonthlyIncomeAmount: grossMonthlyIncomeAmount || 0,
                            housingPaymentAmount: housingPaymentAmount || 0,
                            newPaymentAmount: newPaymentAmount || 0,
                            otherDebtObligationAmount: otherDebtObligationAmount || 0,
                            totalDebtServiceRatioAmount: totalDebtServiceRatioAmount || 0,
                            utilitiesAmount: utilitiesAmount || 0,
                        },
                        finalAuthenticationType,
                        isSellDown: !!isSellDown,
                        status: applicationStatus || null,
                    })
                );

                dispatch(
                    onApplicationResponse({
                        isSellDown: !!isSellDown,
                        response: responseStatus!,
                        status: applicationStatus!,
                    })
                );
            }
            return;
        }
        return next(action);
    };
