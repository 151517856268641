import React from 'react';
import { useIntl } from 'react-intl';
import { PhoneIcon } from '@fairstone/ui/core/components/icons/Phone';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { Table, TableCell, TableRow } from 'components/Table';
import { useAppSelector } from 'store/redux/hooks';
import { sessionState } from 'store/redux/modules/session';

import styles from './HourTable.module.scss';

export const HourTable = (): React.ReactElement => {
    const intl = useIntl();
    const { contactInfo } = useAppSelector(sessionState);

    let localeOpenHours = contactInfo.en.openHours;
    let localePhone = contactInfo.en.phone;

    if (intl.locale.includes('fr')) {
        localeOpenHours = contactInfo.fr.openHours;
        localePhone = contactInfo.fr.phone;
    }

    return (
        <div className={styles.container}>
            <Link className={styles.phone} href={`tel:${localePhone}`} size="large">
                <PhoneIcon className={styles.icon} />
                {localePhone}
            </Link>

            <Typography className={styles.title} variant="h4">
                {t('components.hourTable.title')}
            </Typography>
            <Table className={styles.table}>
                {localeOpenHours.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>{item.days.trim()}</TableCell>
                        <TableCell>
                            {item.hours.trim()} {t('times.timezone')}
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </div>
    );
};
