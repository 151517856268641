import React from 'react';

interface ISecureProps {
    className?: string;
}

export const Secure: React.FC<ISecureProps> = ({ className = '' }) => (
    <svg
        className={className}
        fill="none"
        height="60"
        viewBox="0 0 60 60"
        width="60"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect fill="#F1F1F1" height="60" rx="30" width="60" />
        <path
            d="M31.7874 43.8845C31.4605 44.0822 31.129 44.2665 30.7927 44.4374C30.1566 44.7607 29.5041 45.0358 28.8354 45.2624L28.6651 45.32L28.4941 45.2649C25.1773 44.1951 22.4445 42.0552 20.2981 38.8788C18.1502 35.7 17.0747 32.1619 17.0747 28.2794V19.0578L28.6609 14.6413L40.2471 19.0578V28.2794C40.2471 28.6305 40.2393 28.9598 40.2234 29.267C40.2196 29.3395 40.2154 29.4123 40.2108 29.4856C40.3132 29.4724 40.4143 29.4607 40.5141 29.4506C40.8405 29.4174 41.1807 29.4011 41.5345 29.4011C41.8594 29.4011 42.1776 29.4176 42.4888 29.4509C42.5873 29.4614 42.6852 29.4737 42.7823 29.4876C42.7889 29.3871 42.7946 29.2866 42.7996 29.1861C42.8144 28.8839 42.8219 28.5816 42.8219 28.2794V17.2646L28.6609 11.8667L14.5 17.2646V28.2794C14.5 32.8952 15.8491 37.0875 18.5555 40.872C21.2308 44.6131 24.5977 47.0051 28.6644 48.0781C29.2401 47.9334 29.8027 47.7563 30.3523 47.5468C30.8406 47.3606 31.319 47.1488 31.7874 46.9113V43.8845Z"
            fill="#333333"
        />
        <path
            d="M37.029 48.1333C36.5999 48.1333 36.2321 47.9775 35.9256 47.666C35.6191 47.3544 35.4658 46.9805 35.4658 46.5443V40.3752C35.4658 39.939 35.6191 39.5652 35.9256 39.2536C36.2321 38.942 36.5999 38.7862 37.029 38.7862H37.3049V36.9168C37.3049 35.8886 37.6651 35.0085 38.3854 34.2763C39.1057 33.5441 39.9716 33.178 40.9831 33.178C41.9946 33.178 42.8605 33.5441 43.5808 34.2763C44.3011 35.0085 44.6612 35.8886 44.6612 36.9168V38.7862H44.9371C45.3662 38.7862 45.734 38.942 46.0405 39.2536C46.3471 39.5652 46.5003 39.939 46.5003 40.3752V46.5443C46.5003 46.9805 46.3471 47.3544 46.0405 47.666C45.734 47.9775 45.3662 48.1333 44.9371 48.1333H37.029ZM39.144 38.7862H42.8221V36.9168C42.8221 36.3871 42.6459 35.9432 42.2934 35.5849C41.9409 35.2265 41.5041 35.0474 40.9831 35.0474C40.462 35.0474 40.0252 35.2265 39.6727 35.5849C39.3202 35.9432 39.144 36.3871 39.144 36.9168V38.7862Z"
            fill="#333333"
        />
    </svg>
);
