import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { MERCHANT_CONFIG_API, REGION } from 'config/constants';
import { queryClient } from 'services/client';

import { IMerchantConfigResponse } from './types';

export const getMerchantConfig = async (merchantID: string): Promise<AxiosResponse<IMerchantConfigResponse> | null> => {
    let response: AxiosResponse<IMerchantConfigResponse> | null = null;
    try {
        response = await queryClient.fetchQuery(['getMerchantConfig'], async () => {
            const merchantConfigUrl = `${MERCHANT_CONFIG_API}/${REGION}/merchants/json/${merchantID.toLowerCase()}.json`;
            const { data } = await axios.get(merchantConfigUrl);

            return data;
        });
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

export const getMerchantConfigAsync = createAsyncThunk(
    'merchant/getMerchantConfig',
    async (merchantID: string, { rejectWithValue }) => {
        const response = await getMerchantConfig(merchantID);
        return response ? response : rejectWithValue(response);
    }
);
