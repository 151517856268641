const isLeapYear = (year: number): boolean => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

export const getJulianDay = (year: number, month: number, day: number): number => {
    const daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (isLeapYear(year)) {
        daysInMonth[2] = 29; // Update February days in a leap year
    }

    let julianDay = day;
    for (let i = 1; i < month; i++) {
        julianDay += daysInMonth[i];
    }

    return julianDay;
};
