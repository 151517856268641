import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';
import { queryClient } from 'services/client';
import { RootState } from 'store/redux/types';

import { ICreditApplicationRequest, IProcessCreditAppInfo } from './types';

export const submitCreditApplication = async (info: IProcessCreditAppInfo): Promise<AxiosResponse<any> | null> => {
    let response: AxiosResponse<any> | null = null;

    try {
        response = await queryClient.fetchQuery(
            ['creditAppSubmit'],
            async () => await restAPI.post('/creditapplication/application', info)
        );
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const submitCreditApplicationAsync = createAsyncThunk(
    'processCreditApp/creditAppSubmit',

    async (info: IProcessCreditAppInfo, { fulfillWithValue, rejectWithValue }) => {
        const response = await submitCreditApplication(info);
        if (response && response?.status === 200) {
            return fulfillWithValue(response?.status);
        }

        return rejectWithValue(response);
    }
);

export const resumeCreditApplication = async (
    request: ICreditApplicationRequest
): Promise<AxiosResponse<any> | null> => {
    let response: AxiosResponse<any> | null = null;

    try {
        response = await queryClient.fetchQuery(
            ['resumeCreditApplication'],
            async () => await restAPI.post('/creditapplication/resumeapplication', request)
        );
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const resumeCreditApplicationAsync = createAsyncThunk(
    'processCreditApp/resumeCreditApplication',
    async (_, { fulfillWithValue, getState, rejectWithValue }) => {
        const {
            application: { applicationId },
            flinks: { transactionId },
            session: {
                session: { sessionId },
            },
        } = getState() as RootState;

        const request: ICreditApplicationRequest = {
            applicationIdNotFormated: String(applicationId),
            sessionId,
            transactionId,
        };

        const response = await resumeCreditApplication(request);

        if (response && response?.status === 200) {
            return fulfillWithValue(response?.status);
        }

        return rejectWithValue(response);
    }
);
