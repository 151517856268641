import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useBrazeNotifications } from '@fairstone/functional/core/components/BrazeNotifications/BrazeNotificationsProvider';
import { useSessionManager } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { useFeatureFlags } from '@fairstone/ui/core/providers/FeatureFlags/useFeatureFlags';
import { t } from '@fairstone/ui/core/utils/translate';
import { isTrue } from '@fairstone-commons/utils';
import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { EnvEnum } from '@fairstone-frontend/utils/core/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAnalyticsServices } from 'hooks/useAnalyticsServices/useAnalyticsServices';
import { useInternalNavigation } from 'hooks/useInternalNavigation';
import { useToken } from 'hooks/useToken/useToken';
import isEmpty from 'lodash/isEmpty';

import { Alert } from 'components/Alert';
import { CURRENT_ENV, ONFIDO_MANUAL_UPLOAD, ROAM_URL } from 'config/constants';
import { softReset } from 'store/redux/actions';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { setSalesTrackingId } from 'store/redux/modules/application';
import { merchantState } from 'store/redux/modules/merchant';
import { setOnfidoManualUpload } from 'store/redux/modules/onfido';
import { getOrCreateSessionAsync, sessionState } from 'store/redux/modules/session';
import { ESessionTypes } from 'store/redux/modules/session/types';

import { SplashScreen } from './screens/SplashScreen';
import { salesTrackingValidation, TSalesTracking } from './SalesTracking.validation';

export const SplashPage: React.FC = (): React.ReactElement => {
    const [isKioskDialogOpen, setIsKioskDialogOpen] = useState(false);
    const [isSalesTrackingDialogOpen, setIsSalesTrackingOpen] = useState(false);
    const { onNavigate } = useInternalNavigation();
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const {
        config: { isSalesAssociateTracking, merchantHash, merchantName },
    } = useAppSelector(merchantState);
    const {
        error,
        loading,
        session: { kioskUrl, sessionId, type },
    } = useAppSelector(sessionState);

    const { isActive: isSessionManagerActive, start: sessionManagerTimer } = useSessionManager();
    const { isActive: isBrazeNotificationsActive, startInactivityTimer: brazeNotificationsTimer } =
        useBrazeNotifications();
    const { pushDataLayer, pushSubmitEvent } = useAnalyticsServices();
    const featureFlags = useFeatureFlags();

    const { currentSessionId, encodedIncreditToken } = useToken();

    // Sales Tracking form
    const formOptions = useForm<TSalesTracking>({
        defaultValues: { assistance: '', trackingId: '' },
        mode: 'all',
        resolver: yupResolver(salesTrackingValidation),
    });

    // Temporary URL searchParams (will be manage only in env later)
    useEffect(() => {
        if (CURRENT_ENV === EnvEnum.QA && (ONFIDO_MANUAL_UPLOAD || window.location.search.includes('manualOnfido'))) {
            dispatch(setOnfidoManualUpload(true));
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(encodedIncreditToken)) {
            pushDataLayer({ user_type: 'Ecom' });
        }
    }, [encodedIncreditToken]);

    useEffect(() => {
        const isActive = featureFlags?.analytics === 'true' ? isBrazeNotificationsActive : isSessionManagerActive;
        if (!isEmpty(sessionId) && isActive) {
            onNavigate(`lang-select`);
        }
    }, [sessionId, featureFlags, isBrazeNotificationsActive, isSessionManagerActive]);

    const startTimer = () => {
        sessionManagerTimer();
        brazeNotificationsTimer();
    };

    const handleOpenSalesTrackingDialog = useCallback(async () => {
        setIsSalesTrackingOpen(true);
    }, []);

    const applyHandler = useCallback(
        async (isSalesTrackingId?: string) => {
            if (isEmpty(currentSessionId) && !isEmpty(type) && type === ESessionTypes.KIOSK) {
                setIsKioskDialogOpen(true);
                return;
            }

            dispatch(softReset());

            if (isSalesTrackingId) {
                dispatch(setSalesTrackingId(isSalesTrackingId));
            }

            await dispatch(
                getOrCreateSessionAsync({
                    inCreditToken: encodedIncreditToken || '',
                    merchantHash,
                    sessionId: currentSessionId,
                })
            );
            pushSubmitEvent();
            startTimer();
        },
        [merchantHash, merchantName, type, currentSessionId, encodedIncreditToken, navigator.userAgent, featureFlags]
    );

    const handleOnSubmitSalesTracking = useCallback(
        async (data: TSalesTracking) => {
            await applyHandler(
                data.assistance === 'yes' && data.trackingId && !isEmpty(data.trackingId) ? data.trackingId : undefined
            );
        },
        [applyHandler]
    );

    const handleClickMyAccount = useCallback(() => {
        const oamLink = intl.locale.includes('en') ? `${ROAM_URL}en/login` : `${ROAM_URL}fr/ouvrir-une-session`;
        window.open(oamLink, '_blank');
    }, [intl]);

    useEffect(() => {
        if (error) {
            if (!isEmpty(encodedIncreditToken)) {
                onNavigate('ecom/error');
            } else {
                TrackJS.track('An error occured while creating session');
            }
        }
    }, [error]);

    return (
        <>
            {loading && <Loading>{t('pages.splash.loading')}</Loading>}
            {error && (
                <Alert
                    info={{
                        message: t('global.error'),
                        severity: 'error',
                    }}
                />
            )}
            <SplashScreen
                applyHandler={applyHandler}
                formOptions={formOptions}
                handleOK={() => window.location.assign(kioskUrl)}
                handleOnSubmitSalesTracking={handleOnSubmitSalesTracking}
                handleOpenSalesTrackingDialog={handleOpenSalesTrackingDialog}
                isSalesAssociateTracking={isTrue(isSalesAssociateTracking)}
                kioskDialog={isKioskDialogOpen}
                onClickMyAccount={handleClickMyAccount}
                salesTrackingDialog={isSalesTrackingDialogOpen}
            />
        </>
    );
};
