import React from 'react';

interface IDigitalCreditProps {
    className?: string;
}

export const DigitalCredit: React.FC<IDigitalCreditProps> = ({ className = '' }) => (
    <svg
        className={className}
        fill="none"
        height="60"
        viewBox="0 0 60 60"
        width="60"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect fill="#F1F1F1" height="60" rx="30" width="60" />
        <path
            clip-rule="evenodd"
            d="M29.375 27.0238C29.375 26.4025 29.8787 25.8988 30.5 25.8988H42.4048C43.0261 25.8988 43.5298 26.4025 43.5298 27.0238C43.5298 27.6451 43.0261 28.1488 42.4048 28.1488H30.5C29.8787 28.1488 29.375 27.6451 29.375 27.0238Z"
            fill="#333333"
            fill-rule="evenodd"
        />
        <path
            clip-rule="evenodd"
            d="M29.375 31.7857C29.375 31.1644 29.8787 30.6607 30.5 30.6607H35.2619C35.8833 30.6607 36.3869 31.1644 36.3869 31.7857C36.3869 32.407 35.8833 32.9107 35.2619 32.9107H30.5C29.8787 32.9107 29.375 32.407 29.375 31.7857Z"
            fill="#333333"
            fill-rule="evenodd"
        />
        <path
            clip-rule="evenodd"
            d="M38.8989 31.7857C38.8989 31.1644 39.4025 30.6607 40.0239 30.6607H42.4048C43.0261 30.6607 43.5298 31.1644 43.5298 31.7857C43.5298 32.407 43.0261 32.9107 42.4048 32.9107H40.0239C39.4025 32.9107 38.8989 32.407 38.8989 31.7857Z"
            fill="#333333"
            fill-rule="evenodd"
        />
        <path
            clip-rule="evenodd"
            d="M12.375 20C12.375 17.7218 14.2218 15.875 16.5 15.875H44.5C46.7782 15.875 48.625 17.7218 48.625 20V38C48.625 40.2782 46.7782 42.125 44.5 42.125H16.5C14.2218 42.125 12.375 40.2782 12.375 38V20ZM16.5 18.125C15.4645 18.125 14.625 18.9645 14.625 20V38C14.625 39.0355 15.4645 39.875 16.5 39.875H44.5C45.5355 39.875 46.375 39.0355 46.375 38V20C46.375 18.9645 45.5355 18.125 44.5 18.125H16.5Z"
            fill="#333333"
            fill-rule="evenodd"
        />
        <path
            clip-rule="evenodd"
            d="M22.1667 21.1369C22.788 21.1369 23.2917 21.6405 23.2917 22.2619V24.6428C23.2917 25.2641 22.788 25.7678 22.1667 25.7678C21.5453 25.7678 21.0417 25.2641 21.0417 24.6428V22.2619C21.0417 21.6405 21.5453 21.1369 22.1667 21.1369Z"
            fill="#333333"
            fill-rule="evenodd"
        />
        <path
            clip-rule="evenodd"
            d="M22.1667 33.0417C22.788 33.0417 23.2917 33.5453 23.2917 34.1667V36.5476C23.2917 37.1689 22.788 37.6726 22.1667 37.6726C21.5454 37.6726 21.0417 37.1689 21.0417 36.5476V34.1667C21.0417 33.5453 21.5454 33.0417 22.1667 33.0417Z"
            fill="#333333"
            fill-rule="evenodd"
        />
        <path
            clip-rule="evenodd"
            d="M20.9762 25.7678C20.288 25.7678 19.7202 26.3356 19.7202 27.0238C19.7202 27.712 20.288 28.2797 20.9762 28.2797H23.3571C25.288 28.2797 26.8631 29.8549 26.8631 31.7857C26.8631 33.7165 25.288 35.2917 23.3571 35.2917H19.7857C19.1644 35.2917 18.6607 34.788 18.6607 34.1667C18.6607 33.5453 19.1644 33.0417 19.7857 33.0417H23.3571C24.0453 33.0417 24.6131 32.4739 24.6131 31.7857C24.6131 31.0975 24.0453 30.5297 23.3571 30.5297H20.9762C19.0453 30.5297 17.4702 28.9546 17.4702 27.0238C17.4702 25.093 19.0453 23.5178 20.9762 23.5178H24.5476C25.1689 23.5178 25.6726 24.0215 25.6726 24.6428C25.6726 25.2642 25.1689 25.7678 24.5476 25.7678H20.9762Z"
            fill="#333333"
            fill-rule="evenodd"
        />
    </svg>
);
