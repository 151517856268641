import { EInitialAuthType } from '../types';

export enum ESessionTypes {
    KIOSK = 'kiosk',
    ECOM = 'ecom',
    NONE = '',
}

export enum ERequestType {
    MISMATCH_REQUEST = 'MISMATCH_REQUEST_TYPE_UPDATE_CREDIT_APPLICATION',
    UPDATE_CREDIT_APPLICATION = 'UPDATE_CREDIT_APPLICATION',
    NONE = '',
}

export interface ISessionRequest {
    sessionId?: string;
    inCreditToken?: string;
    merchantHash: string;
    sourceIp?: string;
    type?: ESessionTypes;
}

export interface ISessionInfo {
    sessionId: string;
    inCreditToken: IJWTTokenPayload;
    channelId: string;
    type: string;
    credentials: ICredentials;
    kioskUrl: string;
}
export interface ISessionState {
    loading: boolean;
    error: boolean;
    session: ISessionInfo;
    documentLink: string;
    initialAuthenticationType: EInitialAuthType;
    requestType: ERequestType;
    contactInfo: {
        [key in 'en' | 'fr']: IContactInfo;
    };
}

// getOrCreateSession Response / SessionResponse
export interface IJWTTokenPayload {
    cancelUrl: string;
    exp: number;
    issuer: string;
    region: string;
    returnDomainUrl: string;
    sessionId: string;
    storeId: string;
    transactionAmount: number;
}

export interface IOpenHours {
    days: string;
    hours: string;
}

export type ICredentials = {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    expiration: string;
};

export interface IContactInfo {
    phone: string;
    openHours: IOpenHours[];
}
export interface ISessionResponse {
    channelId: string;
    credentials: ICredentials;
    expirationByTime?: number;
    sessionId: string;
    backendDomain: string;
    flinksDomain: string;
    enContactUs: string;
    frContactUs: string;
    errorPageType?: string;
    inCreditToken?: IJWTTokenPayload;
}
