import { IOnApplicationResponse } from 'store/redux/modules/application/types';
import { EFinalAuthType, EInitialAuthType } from 'store/redux/modules/types';

export const getEcomApprovedPath = (
    approvedLimit: number,
    transactionAmount: number,
    initialAuthenticationType: EInitialAuthType,
    finalAuthenticationType: EFinalAuthType | null,
    isSellDown: IOnApplicationResponse['isSellDown'] = false
): string => {
    if (
        initialAuthenticationType === EInitialAuthType.MANUAL ||
        (finalAuthenticationType !== EFinalAuthType.DIGITAL &&
            finalAuthenticationType !== EFinalAuthType.ONFIDO_ALL_CLEAR)
    ) {
        return 'ecom/identification';
    }

    if (approvedLimit < transactionAmount) {
        return 'ecom/insufficient';
    }

    return isSellDown ? 'second-source/ecom/approved' : 'ecom/approved';
};
