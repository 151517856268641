import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { ON_HARD_RESET_EVENT, ON_SOFT_RESET_EVENT } from 'store/redux/actions';

import { checkServiceAsync } from './actions';
import { IFlinksState } from './types';

export const initialState: IFlinksState = {
    consentTimestamp: 0,
    error: false,
    errorMessage: '',
    flinksDown: false,
    loading: false,
    loginId: '',
    transactionId: uuidv4(),
    updated: false,
};

const resetState = () => ({ ...initialState, transactionId: uuidv4() });

export const flinksSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(checkServiceAsync.fulfilled, (state: IFlinksState) => {
            state.flinksDown = false;
            state.error = false;
            state.loading = false;
        });
        builder.addCase(checkServiceAsync.rejected, (state: IFlinksState) => {
            state.flinksDown = true;
            state.error = true;
            state.loading = false;
        });
        builder.addCase(checkServiceAsync.pending, (state: IFlinksState) => {
            state.flinksDown = false;
            state.error = false;
            state.loading = true;
        });
        builder.addCase(ON_HARD_RESET_EVENT, () => resetState());
        builder.addCase(ON_SOFT_RESET_EVENT, () => resetState());
    },
    initialState,
    name: 'flinks',
    reducers: {
        setConsentTimestamp(state, action: PayloadAction<IFlinksState['consentTimestamp']>) {
            state.consentTimestamp = action.payload;
        },
        setErrorMessage(state, action: PayloadAction<string>) {
            state.errorMessage = action.payload;
        },
        setUpdated(state, action: PayloadAction<Partial<boolean>>) {
            state.updated = action.payload;
        },
    },
});
