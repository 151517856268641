import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_HARD_RESET_EVENT, ON_SOFT_RESET_EVENT } from 'store/redux/actions';

import { ICustomerAddress, ICustomerHousing, ICustomerInformation, ICustomerState } from './types';

export const initialAddress: ICustomerAddress = {
    city: '',
    poBox: '',
    postalCode: '',
    province: '',
    ruralRoute: '',
    streetName: '',
    streetNo: '',
    unitNo: '',
};

export const initialState: ICustomerState = {
    address: initialAddress,
    customer: {
        annualIncome: 0,
        day: '',
        email: '',
        employerName: '',
        employmentStatus: '',
        firstName: '',
        jobTitle: '',
        lastName: '',
        middleName: '',
        month: '',
        phoneNumber: '',
        promotionsInd: false,
        residenceStatus: '',
        sin: 0,
        timeAtPresentEmp: '',
        year: '',
    },

    error: false,
    housing: {
        monthlyPayment: 0,
        rentOrOwn: null,
        yearsAtAddr: '',
    },
    loading: false,
};

export const customerSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(ON_HARD_RESET_EVENT, () => initialState);
        builder.addCase(ON_SOFT_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'customer',
    reducers: {
        setCustomerAddress(state, action: PayloadAction<Partial<ICustomerAddress>>) {
            state.address = { ...state.address, ...action.payload };
        },
        setCustomerHousing(state, action: PayloadAction<Partial<ICustomerHousing>>) {
            state.housing = { ...state.housing, ...action.payload };
        },
        setCustomerInformation(state, action: PayloadAction<Partial<ICustomerInformation>>) {
            state.customer = { ...state.customer, ...action.payload };
        },
    },
});
