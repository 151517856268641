import React from 'react';
import { Provider } from 'react-redux';
import { BrazeNotificationsProvider } from '@fairstone/functional/core/components/BrazeNotifications';
import { Loading } from '@fairstone/ui/core/components/Loading';
import FeatureFlagsProvider from '@fairstone/ui/core/providers/FeatureFlags/FeatureFlagsProvider';
import { LocaleContextProvider } from '@fairstone/ui/core/providers/Intl';
import { Intl as IntlProvider } from '@fairstone/ui/core/providers/Intl';
import { QueryClientProvider } from '@tanstack/react-query';
import { PersistGate } from 'redux-persist/integration/react';

import { DEFAULT_LANGUAGE, FEATURE_PROVIDER } from 'config/constants';
import translations, { locales } from 'locales';
import { queryClient } from 'services/client';
import { persistedStore, store } from 'store/redux/store';

import { MerchantProvider } from './Merchant';
import { SessionProvider } from './Session';
import ThemeProvider from './Theme';

export interface IProvider {
    children: React.ReactNode;
}

export default ({ children }: IProvider): React.ReactElement => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
            <QueryClientProvider client={queryClient}>
                <MerchantProvider>
                    <ThemeProvider>
                        <LocaleContextProvider>
                            <FeatureFlagsProvider configs={FEATURE_PROVIDER} loadingComponent={<Loading />}>
                                <IntlProvider langCode={DEFAULT_LANGUAGE} locales={locales} translations={translations}>
                                    <SessionProvider>
                                        <BrazeNotificationsProvider>{children}</BrazeNotificationsProvider>
                                    </SessionProvider>
                                </IntlProvider>
                            </FeatureFlagsProvider>
                        </LocaleContextProvider>
                    </ThemeProvider>
                </MerchantProvider>
            </QueryClientProvider>
        </PersistGate>
    </Provider>
);
