export const formatPhoneNumber = (phoneNumber: string): string => {
    // Remove any non-digit characters from the phone number
    phoneNumber = phoneNumber.replace(/\D/g, '');

    // Check if the phone number is valid
    if (phoneNumber.length !== 10) {
        return '';
    }

    // Format the phone number
    const areaCode = phoneNumber.substring(0, 3);
    const firstPart = phoneNumber.substring(3, 6);
    const secondPart = phoneNumber.substring(6, 10);

    return `${areaCode}-${firstPart}-${secondPart}`;
};
