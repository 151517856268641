import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { setIsAppFlow, setLatestStep } from 'store/redux/modules/application';
import { EScreenUrlPathsType } from 'store/redux/modules/types';
import { findStepNameByURL } from 'utils/routes/routes';

import { backButtonBlockedList } from './types/backBlock';

export interface IUseInternalNavigationReturn {
    onNavigate: (route: string) => void;
}
export const useInternalNavigation = (): IUseInternalNavigationReturn => {
    const { merchant } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onNavigate = (route: string) => {
        const stepName = findStepNameByURL(`${'/'.concat(route) as EScreenUrlPathsType}`);
        dispatch(setLatestStep(stepName));
        dispatch(setIsAppFlow(true));
        navigate(`/${merchant}/${route}`);

        window.onpopstate = () => {
            if (
                window.location.pathname.includes('digital/validate') ||
                window.location.pathname.includes('onfido/validate')
            )
                navigate(`/${merchant}/${route}`);

            if (backButtonBlockedList.includes(stepName)) navigate(`/${merchant}/${route}`);
        };
    };

    return { onNavigate };
};
