import React from 'react';
import { TPartialFairstoneTheme } from '@fairstone/ui/core/providers/DefaultTheme';
import FairstoneStylesProvider from '@fairstone/ui/core/providers/FairstoneStylesProvider';

import { useGetMerchantTheme } from 'services/theme';
import { getMerchantParam } from 'utils/url/getMerchantParam';

const defaultTheme: TPartialFairstoneTheme = {
    palette: {
        components: {
            loading: {
                backgroundcolor: '#525252',
            },
        },
    },
};

const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const merchantID = getMerchantParam();
    const { data: merchantTheme } = useGetMerchantTheme(merchantID);
    const theme = merchantTheme || defaultTheme;

    return <FairstoneStylesProvider children={children} theme={theme} />;
};

export default ThemeProvider;
