import { getJulianDay } from './getJulianDay';

export const getTimestampForGA = (date: Date): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
    const day = date.getDate();

    const paddedJulianDay = getJulianDay(year, month, day).toString().padStart(3, '0');
    const paddedHours = date.getHours().toString().padStart(2, '0');
    const paddedMinutes = date.getMinutes().toString().padStart(2, '0');
    const paddedSeconds = date.getSeconds().toString().padStart(2, '0');

    // 0+yyyyDDDhhmmss
    return `0${year}${paddedJulianDay}${paddedHours}${paddedMinutes}${paddedSeconds}`;
};
