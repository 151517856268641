import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useAppSelector } from 'store/redux/hooks';
import { sessionState } from 'store/redux/modules/session';
import { getLastPath } from 'utils/url/getLastPath';

export const useSessionRedirection = (): void => {
    const {
        session: { sessionId },
    } = useAppSelector(sessionState);
    const { merchant } = useParams();
    const navigate = useNavigate();

    const { pathname } = useLocation();
    useEffect(() => {
        const lastPathPart = getLastPath(pathname);
        if (
            lastPathPart !== merchant &&
            lastPathPart !== 'maintenance' &&
            lastPathPart !== 'error' &&
            lastPathPart !== 'fr' &&
            pathname !== '/404' &&
            isEmpty(sessionId)
        ) {
            navigate(merchant!);
        }
    }, [pathname, sessionId]);
};
