import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useAppSelector } from 'store/redux/hooks';
import { applicationState, setIsAppFlow } from 'store/redux/modules/application';
import { getExceptMerchantPath } from 'utils/url/getExceptMerchantPath';

export interface StepManagerProps {
    pathname?: string;
    search?: string;
    children: React.ReactElement;
}

export const StepManager: React.FC<React.PropsWithChildren<StepManagerProps>> = ({ children }) => {
    const location = useLocation();
    const { merchant } = useParams();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const { isAppFlow, latestStep } = useAppSelector(applicationState);
    const currentStepName = getExceptMerchantPath(location.pathname);

    useEffect(() => {
        if (!isAppFlow && currentStepName !== '/') {
            if (currentStepName !== latestStep) {
                dispatch(setIsAppFlow(true));
            }
        }
    }, [currentStepName, isAppFlow, latestStep]);
    if (!isAppFlow && currentStepName !== '/' && !isEmpty(merchant)) {
        if (currentStepName !== latestStep) {
            let urlSearchParams = searchParams.toString();
            if (!isEmpty(urlSearchParams)) {
                urlSearchParams = `?${urlSearchParams}`;
            }
            let lang = '/fr';
            if (!currentStepName.includes(lang)) {
                lang = '';
            }
            return <Navigate replace={true} to={`/${merchant}${lang}${latestStep}${urlSearchParams}`} />;
        }
    }
    return children;
};
