import { configureStore } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { onApplicationResponseEventMiddleware } from './middleware/onApplicationResponse';
import { onIOTDataEventMiddleware } from './middleware/onIOTData';
import { onResetEventMiddleware } from './middleware/onReset';
import rootReducer from './reducers';
import { rootPersistConfig } from './store-persist-config';

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store: any = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(onIOTDataEventMiddleware)
            .concat(onApplicationResponseEventMiddleware)
            .concat(onResetEventMiddleware)
            .concat(LogRocket.reduxMiddleware()),
    reducer: persistedReducer,
});

export const persistedStore = persistStore(store);
