import { RootState } from 'store/redux/types';

import { merchantSlice } from './reducers';
import { IMerchantState } from './types';

export const merchantState = (state: RootState): IMerchantState => state.merchant;

export { getMerchantConfigAsync } from './actions';

export default merchantSlice.reducer;
