import axios, { AxiosResponse } from 'axios';

import { API_URL } from 'config/constants';
import { signRequest } from 'utils/auth/url';

export const restAPI = axios.create({
    baseURL: API_URL,
});

export const setApiKey = (apiKey: string): void => {
    restAPI.defaults.headers['x-api-key'] = apiKey;
};

export const axiosErrorHandler = (error: AxiosResponse<any>): any => Promise.reject({ ...error });

export const axiosSuccessHandler = (response: AxiosResponse<any>): any => Promise.resolve({ ...response });

// inject merchant api keys
restAPI.interceptors.request.use(async (config) => {
    const { baseURL, data, method, url } = config;
    const signedRequest = await signRequest(
        `${baseURL}${url}`,
        method!,
        'execute-api',
        { 'x-api-key': restAPI.defaults.headers['x-api-key'] },
        data
    );
    config.headers = { ...config.headers, ...signedRequest.headers };
    return config;
});

/*restAPI.interceptors.response.use(
    (response) => axiosSuccessHandler(response),
    (error) => axiosErrorHandler(error)
);*/
