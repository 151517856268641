import { RootState } from 'store/redux/types';

import { applicationSlice } from './reducers';
import { ICreditApplicationState } from './types';
export { resumeCreditApplicationAsync, submitCreditApplicationAsync } from './actions';

export const applicationState = (state: RootState): ICreditApplicationState => state.application;

export const { setCreditApplicationData, setIsAppFlow, setLatestStep, setPrivacyConsentTimestamp, setSalesTrackingId } =
    applicationSlice.actions;
export default applicationSlice.reducer;
