import React from 'react';
import { icons, InputAdornment } from '@fairstone/ui/core';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import moment from 'moment';

export const isValidSin = (sin: number): boolean => {
    // Strip out any formatting, only leave numbers
    const sinDigits = Array.from(sin.toString(), Number);

    // Total number of digits is 9
    // The number must not start with a 0 or 8
    if (sinDigits.length === 9 && sinDigits[0] !== 0 && sinDigits[0] !== 8) {
        let sum = 0;

        sinDigits.map((digit, index) => {
            if (index % 2 === 0) {
                // Add every other index value; no need to multiply by 1
                sum += digit;
            } else {
                // Multiply every second digit by 2
                const result = digit * 2;
                // Make sure you add the individual digits
                sum += Math.floor(result / 10) + (result % 10);
            }
        });

        // The result of the additions must be evenly divisible by 10
        return sum % 10 === 0;
    }

    return false;
};
export const validatePhoneNumber = (value: string | undefined): boolean => {
    if (!value) {
        return false;
    }
    // Only run the 4th character check for 10-digit phone numbers
    if (value.length === 12 && (value[4] === '0' || value[4] === '1')) {
        return false;
    }

    return true;
};
export const calculateAge = (day: string, month: string, year: string): number => {
    if (day.length && month.length && year.length) {
        const today = moment();
        const dob = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
        const age = today.diff(dob, 'years');

        return age;
    }

    return 0;
};

export const isLegalAge = (day: string, month: string, year: string): boolean => calculateAge(day, month, year) >= 18;

export const getValidAdornmentIcon = (
    value: string | number | undefined,
    showValidation: boolean,
    invalid: boolean,
    styles: {
        readonly [key: string]: string;
    }
): React.ReactNode =>
    showValidation && (!isEmpty(value) || isNumber(value)) ? (
        <InputAdornment position="end">
            <icons.Check className={styles.checkIcon} />
        </InputAdornment>
    ) : invalid ? (
        <InputAdornment position="end">
            <icons.ErrorOutline className={styles.errorIcon} />
        </InputAdornment>
    ) : undefined;
