export const getExceptMerchantPath = (url: string): string => {
    // trim spaces
    const trimmedUrl = url.trim();

    // base case
    if (trimmedUrl === '/') return trimmedUrl;

    // Split the URL by '/'
    const urlParts = url.trim().split('/');

    // Get the last part of the URL (ignoring any trailing slashes)
    let lastPath = [''];
    if (urlParts.length > 2) {
        lastPath = urlParts.slice(2, urlParts.length);
    } else {
        return url;
    }

    return '/'.concat(lastPath.join('/'));
};
