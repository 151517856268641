import React from 'react';
import { Card } from '@fairstone/ui/core/components/Card';
import cx from 'classnames';

import { useMobileView } from 'utils/mediaQuery/useMobileView';

import styles from './Content.module.scss';

interface IContentProps {
    className?: string;
}
export const Content: React.FC<React.PropsWithChildren<IContentProps>> = ({ children, className }) => {
    const isMobileView = useMobileView();
    return (
        <main className={cx(className, styles.container)}>
            {isMobileView ? (
                children
            ) : (
                <Card className={styles.card} variant="outlined">
                    {children}
                </Card>
            )}
        </main>
    );
};
