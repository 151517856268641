import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ON_HARD_RESET_EVENT, ON_SOFT_RESET_EVENT } from 'store/redux/actions';

import { IOnfidoState } from './types';

export const initialState: IOnfidoState = {
    error: false,
    isManualUpload: false,
    loading: false,
    status: null,
};

export const onfidoSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(ON_HARD_RESET_EVENT, () => initialState);
        builder.addCase(ON_SOFT_RESET_EVENT, () => initialState);
    },
    initialState,
    name: 'onfido',
    reducers: {
        setOnfidoManualUpload(state, action: PayloadAction<boolean>) {
            state.isManualUpload = action.payload;
        },
        setOnfidoStatus(state, action: PayloadAction<number>) {
            state.status = action.payload;
        },
    },
});
