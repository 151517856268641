import { Amplify } from 'aws-amplify';

import { COGNITO_IDENTITY_POOL, REGION } from './constants';

Amplify.configure({
    Auth: {
        identityPoolId: COGNITO_IDENTITY_POOL,
        region: REGION,
    },
});
