import { EInitialAuthType } from '../types';

export interface IFlinksState {
    error: boolean;
    errorMessage: string;
    flinksDown: boolean;
    loading: boolean;
    loginId: string;
    transactionId: string;
    updated: boolean;
    consentTimestamp: number;
}

export interface IKycResponse {
    name?: string;
    email?: string;
    phoneNumber?: string;
    civicAddress?: string;
    city?: string;
    country?: string;
    postalCode?: string;
    province?: string;
}

export enum EIOTFlinksType {
    KYC = 'kyc saved',
}

export interface IIOTFlinksResponse {
    kyc: IKycResponse;
    type: EIOTFlinksType;
}

export interface IIOTMismatchResponse {
    finalAuthenticationType: EInitialAuthType;
    mismatchAttribute: boolean;
}
