import { isTrue } from '@fairstone-commons/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';

import { ON_HARD_RESET_EVENT, ON_SOFT_RESET_EVENT } from 'store/redux/actions';
import { RootState } from 'store/redux/types';
import { getFormattedDOB } from 'utils/format/formatDOB';
import { formatNumberToString } from 'utils/format/formatNumber';
import { formatStringSpecialCharacters } from 'utils/format/formatString';

import { EScreenUrlPaths, EStepName } from '../types';

import { resumeCreditApplicationAsync, submitCreditApplicationAsync } from './actions';
import { ICreditApplicationState, IProcessCreditAppInfo } from './types';

export const initialState: ICreditApplicationState = {
    accountNumber: 0,
    applicationId: 0,
    applicationIdFormatted: '',
    creditLimit: 0,
    debtRatio: {
        grossMonthlyIncomeAmount: 0,
        housingPaymentAmount: 0,
        newPaymentAmount: 0,
        otherDebtObligationAmount: 0,
        totalDebtServiceRatioAmount: 0,
        utilitiesAmount: 0,
    },
    error: false,
    finalAuthenticationType: null,
    isAppFlow: false,
    isSellDown: false,
    latestStep: '',
    loading: false,
    privacyConsentTimestamp: 0,
    salesTrackingId: '',
    status: null,
    submitted: false,
};

export const createApplicationSelector = createSelector(
    [
        (state) => state,
        // Take the second arg, `category`, and forward to the output selector
        (_, language: string) => language,
        (_, __, brazeId: string) => brazeId,
    ],
    // Output selector gets (`items, category)` as args
    (state, language: string, brazeId: string) => {
        const {
            application: { privacyConsentTimestamp, salesTrackingId },
            customer: { address, customer, housing },
            flinks,
            merchant: {
                config: { isSalesAssociateTracking, merchantUrl },
            },
            session,
        } = state as RootState;

        const { fullAddress, streetName, streetNo, unitNo } = address;

        const formattedAddress =
            unitNo || streetNo || streetName
                ? `${unitNo && unitNo.length > 0 ? `${unitNo}-` : ''}${
                      streetNo && streetNo.length > 0 ? streetNo : ''
                  } ${streetName ?? ''}`.trim()
                : fullAddress ?? '';

        const formattedDOB = getFormattedDOB(customer);

        const info: IProcessCreditAppInfo = {
            addressLine1: formattedAddress,
            annualIncome: formatNumberToString(customer.annualIncome),
            brazeId,
            city: address.city,
            country: 'CAN',
            dateofBirth: formattedDOB,
            documentUrl: session.documentLink,
            email: customer.email,
            employerName: customer.employerName,
            employmentStatus: customer.employmentStatus,
            firstName: customer.firstName,
            initialAuthenticationType: session.initialAuthenticationType.toLowerCase(),
            jobTitle: customer.jobTitle,
            languageIndicator: language.includes('fr') ? 'F' : 'E',
            lastName: customer.lastName,
            merchantUrl,
            middleName: customer.middleName,
            mobilePhone: formatStringSpecialCharacters(customer.phoneNumber),
            monthlyPayment: formatNumberToString(housing.monthlyPayment),
            poBox: address.poBox,
            postalCode: formatStringSpecialCharacters(address.postalCode),
            privacyConsentTimestamp: privacyConsentTimestamp,
            promotionsInd: customer.promotionsInd ? '1' : '0',
            propertyType: housing.rentOrOwn,
            requestType: session.requestType,
            residenceStatus: customer.residenceStatus,
            ruralRoute: address.ruralRoute,
            salesTrackingId: isTrue(isSalesAssociateTracking) ? salesTrackingId : undefined,
            sessionId: session.session.sessionId,
            sin: customer.sin > 0 ? String(customer.sin) : '',
            state: address.province,
            timeAtPresentEmp: customer.timeAtPresentEmp,
            transactionId: flinks.transactionId,
            userAgent: window.navigator.userAgent,
            yrsAtAddr: housing.yearsAtAddr,
        };

        return info;
    }
);

export const applicationSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(submitCreditApplicationAsync.fulfilled, (state: any) => {
            state.loading = false;
            state.submitted = true;
        });
        builder.addCase(submitCreditApplicationAsync.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(submitCreditApplicationAsync.rejected, (state: any) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(resumeCreditApplicationAsync.fulfilled, (state: any) => {
            state.loading = false;
        });
        builder.addCase(resumeCreditApplicationAsync.pending, (state: any) => {
            state.loading = true;
        });
        builder.addCase(resumeCreditApplicationAsync.rejected, (state: any) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(ON_HARD_RESET_EVENT, () => initialState);
        builder.addCase(ON_SOFT_RESET_EVENT, (state: any) => ({
            ...initialState,
            brazeId: state.brazeId,
            salesTrackingId: state.salesTrackingId,
        }));
    },
    initialState,
    name: 'application',
    reducers: {
        setCreditApplicationData(
            state,
            action: PayloadAction<
                Omit<
                    ICreditApplicationState,
                    | 'error'
                    | 'loading'
                    | 'submitted'
                    | 'latestStep'
                    | 'isAppFlow'
                    | 'privacyConsentTimestamp'
                    | 'brazeId'
                    | 'salesTrackingId'
                >
            >
        ) {
            const {
                accountNumber,
                applicationId,
                applicationIdFormatted,
                creditLimit,
                debtRatio,
                finalAuthenticationType,
                isSellDown,
                status,
            } = action.payload;
            state.accountNumber = accountNumber;
            state.applicationIdFormatted = applicationIdFormatted;
            state.applicationId = applicationId;
            state.creditLimit = creditLimit;
            state.debtRatio = debtRatio;
            state.status = status;
            state.finalAuthenticationType = finalAuthenticationType;
            state.isSellDown = isSellDown;
        },
        setIsAppFlow(state, action: PayloadAction<ICreditApplicationState['isAppFlow']>) {
            state.isAppFlow = action.payload;
        },

        setLatestStep(state, action: PayloadAction<EStepName>) {
            state.latestStep = EScreenUrlPaths[action.payload];
        },

        setPrivacyConsentTimestamp(state, action: PayloadAction<ICreditApplicationState['privacyConsentTimestamp']>) {
            state.privacyConsentTimestamp = action.payload;
        },

        setSalesTrackingId(state, action: PayloadAction<ICreditApplicationState['salesTrackingId']>) {
            state.salesTrackingId = action.payload;
        },
    },
});
