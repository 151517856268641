import React, { useEffect, useState } from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';
import { useBrazeNotifications } from '@fairstone/functional/core/components/BrazeNotifications/BrazeNotificationsProvider';
import { useSessionManager } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { useFeatureFlags } from '@fairstone/ui/core/providers/FeatureFlags/useFeatureFlags';
import { useScrollToTop } from 'hooks/useScrollToTop/useScrollToTop';
import { useSessionRedirection } from 'hooks/useSessionRedirection/useSessionRedirection';

import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { ProgressBar } from 'components/ProgressBar';
// import { trackPageViews } from 'services/eventBridge';
import { getLastPath } from 'utils/url/getLastPath';

import { Content } from './Content';

import styles from './App.module.scss';

interface AppLayoutProps extends React.PropsWithChildren {
    disableProgressBar?: boolean;
}
export const AppLayout: React.FC<AppLayoutProps> = ({ children, disableProgressBar = false }) => {
    useScrollToTop();
    useSessionRedirection();
    const { start: sessionManagerTimer } = useSessionManager();
    const { startInactivityTimer: brazeNotificationsTimer } = useBrazeNotifications();
    const featureFlags = useFeatureFlags();
    const { merchant } = useParams();

    const matches = useMatch(':merchant');

    const [showLocale, setShowLocale] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (matches === null) {
            if (featureFlags?.analytics === 'true') {
                brazeNotificationsTimer();
            }
            sessionManagerTimer();
        }
    }, [matches, featureFlags]);

    useEffect(() => {
        const pageWithLocaleSwitch = ['lang-select', 'fr', merchant];
        if (pageWithLocaleSwitch.includes(getLastPath(location.pathname))) {
            setShowLocale(true);
        } else {
            setShowLocale(false);
        }
    }, [location.pathname, merchant]);

    // useEffect(() => {
    //     trackPageViews();
    // }, [location.pathname]);

    return (
        <div className={styles.layout}>
            <Header className={styles.header} showLocale={showLocale} />
            {!disableProgressBar && <ProgressBar />}
            <Content className={styles.content}>{children}</Content>
            <Footer className={styles.footer} />
        </div>
    );
};
