import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import applicationReducer from './modules/application';
import customerReducer from './modules/customer';
import flinksReducer from './modules/flinks';
import merchantReducer from './modules/merchant';
import onfidoReducer from './modules/onfido';
import sessionReducer from './modules/session';
import { applicationPersistConfig, merchantPersistConfig, sessionPersistConfig } from './store-persist-config';

export default combineReducers({
    application: persistReducer(applicationPersistConfig, applicationReducer),
    customer: customerReducer,
    flinks: flinksReducer,
    merchant: persistReducer(merchantPersistConfig, merchantReducer),
    onfido: onfidoReducer,
    session: persistReducer(sessionPersistConfig, sessionReducer),
});
