import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { FLINKS_URL } from 'config/constants';
import { queryClient } from 'services/client';

import { ERespStatusCodes } from '../types';

export const checkService = async (): Promise<AxiosResponse | null> => {
    let response: AxiosResponse | null = null;

    try {
        response = await queryClient.fetchQuery(['createService'], async () => await axios.head(FLINKS_URL));
    } catch (error: any) {
        TrackJS.track(error);
    }

    return response;
};

export const checkServiceAsync = createAsyncThunk('flinks/createService', async (_, { rejectWithValue }) => {
    const response = await checkService();

    if (response && response.status === ERespStatusCodes.OK) {
        return response;
    }

    return rejectWithValue(response);
});
