import { createAction } from '@reduxjs/toolkit';

import { IIOTResponse, IOnApplicationResponse } from './modules/application/types';
import { IIOTFlinksResponse } from './modules/flinks/types';
import { IIOTOnfidoResponse } from './modules/onfido/types';

export const ON_APPLICATION_RESPONSE = 'application/response/store';
export const onApplicationResponse = createAction<IOnApplicationResponse>(ON_APPLICATION_RESPONSE);

export const ON_IOT_DATA = 'application/iot/store';
export const onIotData = createAction<IIOTResponse | IIOTOnfidoResponse | IIOTFlinksResponse>(ON_IOT_DATA);

export const ON_RESET_EVENT = 'application/reset/store';
export const resetStore = createAction<string>(ON_RESET_EVENT);

export const ON_HARD_RESET_EVENT = 'application/hardreset/store';
export const hardReset = createAction<void>(ON_HARD_RESET_EVENT);

export const ON_SOFT_RESET_EVENT = 'application/softreset/store';
export const softReset = createAction<void>(ON_SOFT_RESET_EVENT);
