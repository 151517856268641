import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { restAPI } from 'services/api';
import { queryClient } from 'services/client';

import { ISessionRequest, ISessionResponse } from './types';

export const getOrCreateSession = async (
    sessionRequest: ISessionRequest
): Promise<AxiosResponse<ISessionResponse> | null> => {
    let response: AxiosResponse<ISessionResponse> | null = null;

    try {
        response = await queryClient.fetchQuery(
            ['getOrCreateSession'],
            async () => await restAPI.put('/digitalid/session', sessionRequest)
        );
    } catch (error: any) {
        TrackJS.track(error);
    }
    return response;
};

export const getOrCreateSessionAsync = createAsyncThunk(
    'session/getOrCreateSession',
    async (sessionRequest: ISessionRequest, { rejectWithValue }) => {
        const response = await getOrCreateSession(sessionRequest);

        if (response && response?.data) {
            return response?.data;
        }

        return rejectWithValue(response);
    }
);
