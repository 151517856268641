import { resetAnalytics } from '@fairstone/functional/core/utils/analytics/analytics';
import { Middleware } from 'redux';

import { ON_APPLICATION_RESPONSE } from 'store/redux/actions';
import { RootState } from 'store/redux/types';
import { getEcomApprovedPath } from 'utils/url/getEcomApprovedPath';
import { redirect } from 'utils/url/redirect';

import { EApplicationStatus, EResponseStatus, IOnApplicationResponse } from '../modules/application/types';
import { ESessionTypes } from '../modules/session/types';
import { EFinalAuthType, EInitialAuthType } from '../modules/types';

export const onApplicationResponseEventMiddleware: Middleware<Record<string, unknown>, RootState> =
    ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
        const { payload, type } = action;

        if (type === ON_APPLICATION_RESPONSE) {
            const applicationResponse = payload as IOnApplicationResponse;
            const { isSellDown, response, status } = applicationResponse;
            const {
                application: { creditLimit, finalAuthenticationType },
                merchant: {
                    config: { merchantContext },
                },
                session: {
                    initialAuthenticationType,
                    session: {
                        inCreditToken: { transactionAmount },
                        type,
                    },
                },
            } = getState();

            const prefix = type === ESessionTypes.ECOM ? '/ecom' : '';
            const secondSourcePrefix = isSellDown ? '/second-source' : '';

            if (response === EResponseStatus.SUCCESS) {
                if (status !== EApplicationStatus.HCCG) resetAnalytics();
                switch (status) {
                    case EApplicationStatus.APPROVED: {
                        let path;
                        if (type === ESessionTypes.ECOM) {
                            const redirectPath = getEcomApprovedPath(
                                creditLimit,
                                transactionAmount,
                                initialAuthenticationType,
                                finalAuthenticationType,
                                isSellDown
                            );
                            path = `/${redirectPath}`;
                        } else {
                            const digitalFlow =
                                initialAuthenticationType === EInitialAuthType.MANUAL ||
                                (finalAuthenticationType !== EFinalAuthType.DIGITAL &&
                                    finalAuthenticationType !== EFinalAuthType.ONFIDO_ALL_CLEAR)
                                    ? ''
                                    : `/${initialAuthenticationType.toLocaleLowerCase()}`;
                            path = `${digitalFlow}${secondSourcePrefix}/approved`;
                        }
                        redirect(`${merchantContext}${path}`, dispatch);
                        return;
                    }
                    case EApplicationStatus.DECLINED:
                        redirect(`${merchantContext}${prefix}${secondSourcePrefix}/declined`, dispatch);
                        return;
                    case EApplicationStatus.PENDING:
                        redirect(`${merchantContext}${prefix}${secondSourcePrefix}/pending`, dispatch);
                        return;
                    case EApplicationStatus.HCCG:
                        redirect(`${merchantContext}/debt-ratio`, dispatch);
                        return;
                    default:
                }
            }

            return;
        }

        return next(action);
    };
