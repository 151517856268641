import React from 'react';
import { useSessionManager } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { Typography } from '@fairstone/ui/core';
import { t } from '@fairstone/ui/core/utils/translate';

export const CountdownDisplay: React.FC = () => {
    const { seconds } = useSessionManager();

    return <Typography variant="body2-semibold">{t('pages.session.title', { seconds })}.</Typography>;
};
