import { ERentOrOwn } from '../customer/types';
import { ERequestType } from '../session/types';
import { EFinalAuthType, EScreenUrlPathsType } from '../types';

export interface IDebtRatioState {
    housingPaymentAmount: number;
    utilitiesAmount: number;
    otherDebtObligationAmount: number;
    grossMonthlyIncomeAmount: number;
    totalDebtServiceRatioAmount: number;
    newPaymentAmount: number;
}
export interface ICreditApplicationState {
    loading: boolean;
    error: boolean;
    submitted: boolean;
    status: EApplicationStatus | null;
    applicationId: number;
    applicationIdFormatted: string;
    isSellDown: boolean;
    debtRatio: IDebtRatioState;
    accountNumber: number;
    creditLimit: number;
    finalAuthenticationType: EFinalAuthType | null;
    latestStep: EScreenUrlPathsType | '';
    isAppFlow: boolean;
    privacyConsentTimestamp: number;
    salesTrackingId: string;
}

export interface ICreditApplicationFallbackRequest {
    sid: string;
    tid: string;
}

export interface ICreditApplicationRequest {
    sessionId: string;
    applicationIdNotFormated: string;
    transactionId: string;
}

// Based on backend request
export interface IProcessCreditAppInfo {
    addressLine1: string;
    annualIncome: string;
    applicationID?: string | null;
    applicationIdNotFormatted?: string;
    city: string;
    country: string;
    dateofBirth: string;
    documentUrl: string;
    employerName: string;
    employmentStatus: string;
    email: string;
    firstName: string;
    initialAuthenticationType: string;
    jobTitle: string;
    languageIndicator: string;
    lastName: string;
    merchantHash?: string;
    merchantName?: string;
    merchantUrl: string;
    middleName?: string | null;
    mobilePhone: string;
    monthlyPayment: string;
    poBox: string | null;
    postalCode: string;
    privacyConsentTimestamp: number;
    promotionsInd: string;
    propertyType: ERentOrOwn | null;
    requestType: ERequestType | null;
    residenceStatus: string;
    ruralRoute: string | null;
    sessionId: string;
    sin: string;
    state: string;
    timeAtPresentEmp: string;
    transactionId: string;
    userAgent: string;
    yrsAtAddr: string;
    brazeId: string;
    salesTrackingId?: string;
}

export enum EResponseStatus {
    SUCCESS = 'A',
}

export enum EApplicationStatus {
    APPROVED = 'A',
    DECLINED = 'D',
    PENDING = 'P',
    HCCG = 'Q',
}
export enum EIOTPublishResponseType {
    SESSION_FAILED = 'SESSION_FAILED',
}

export interface IOnApplicationResponse {
    response: EResponseStatus | null;
    status: EApplicationStatus | null;
    isSellDown: boolean;
}

export interface IIOTResponse {
    dateProcessed?: string;
    timeProcessed?: string;
    responseStatus?: EResponseStatus | null;
    numberofErrorConditions?: number;
    errorConditionArray?: any[];
    applicantFullName?: string;
    applicationStatus?: EApplicationStatus;
    credAppStatus?: string;
    applicationID?: string;
    accountNumber?: string;
    accountNumberNotFormatted?: string;
    outCreditToken?: string;
    applicationIdNotFormated?: string;
    housingPaymentAmount?: number;
    utilitiesAmount?: number;
    newPaymentAmount?: number;
    otherDebtObligationAmount?: number;
    grossMonthlyIncomeAmount?: number;
    totalDebtServiceRatioAmount?: number;
    creditLimit?: number;
    additionalField1?: string;
    currentProcess?: string;
    auditEntry?: Record<string, any>;
    finalAuthenticationType: EFinalAuthType;
    mismatchAttribute?: boolean;
    selldownApplicationID?: number;
    selldownType?: number;
    zblockTimestamp?: string;
    isSellDown?: boolean;
}
