import { EInitialAuthType } from 'store/redux/modules/types';

interface ISteps {
    [key: string]: number;
}

const getDecisionSteps = (stepNumber: number): ISteps => ({
    approved: stepNumber,
    declined: stepNumber,
    'ecom/approved': stepNumber,
    'ecom/declined': stepNumber,
    'ecom/identification': stepNumber,
    'ecom/insufficient': stepNumber,
    'ecom/pending': stepNumber,
    'ecom/second-source/approved': stepNumber,
    'ecom/second-source/declined': stepNumber,
    'ecom/second-source/identification': stepNumber,
    'ecom/second-source/insufficient': stepNumber,
    'ecom/second-source/pending': stepNumber,
    identification: stepNumber,
    pending: stepNumber,
    'second-source/approved': stepNumber,
});

const onfidoSteps: ISteps = {
    'debt-ratio': 11,
    'onfido/address': 5,
    'onfido/approved': 12,
    'onfido/confirmation': 3,
    'onfido/disclaimer': 1,
    'onfido/housing': 6,
    'onfido/invalid-document': 3,
    'onfido/second-source/approved': 12,
    'onfido/tell-us-about-you': 4,
    'onfido/validate': 10,
    'onfido/verify': 2,
    'onfido/waiting': 9,
    'privacy-bill': 7,
    'terms-and-conditions': 8,
    ...getDecisionSteps(12),
};

const digitalSteps: ISteps = {
    'debt-ratio': 9,
    'digital/address': 4,
    'digital/approved': 10,
    'digital/disclaimer': 1,
    'digital/housing': 5,
    'digital/second-source/approved': 10,
    'digital/tell-us-about-you': 3,

    'digital/validate': 8,
    'digital/verify': 2,
    'privacy-bill': 6,
    'terms-and-conditions': 7,
    ...getDecisionSteps(10),
};

const genericSteps: ISteps = {
    address: 2,
    'debt-ratio': 6,
    housing: 3,
    maintenance: 0,
    'privacy-bill': 4,
    'tell-us-about-you': 1,
    'terms-and-conditions': 5,
    ...getDecisionSteps(7),
};

export const getPageProgress = (pathname: string, initialAuthenticationType: EInitialAuthType): number | null => {
    let steps = genericSteps;
    const params = pathname.split('/');

    switch (initialAuthenticationType.toLocaleLowerCase()) {
        case 'onfido':
            steps = onfidoSteps;
            break;
        case 'digital':
            steps = digitalSteps;
            break;
    }

    // <= 2 return 0 since only contains merchant
    if (params.length <= 2 || params[1] === '404' || steps[pathname.replace(`/${params[1]}/`, '')] === undefined) {
        return null;
    }

    let numberOfSteps = 1;

    for (const i in steps) {
        numberOfSteps = steps[i] > numberOfSteps ? steps[i] : numberOfSteps;
    }
    return (steps[pathname.replace(`/${params[1]}/`, '')] ?? 0) * (100 / numberOfSteps);
};
