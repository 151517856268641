import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getMerchantConfigAsync } from './actions';
import {
    EMerchantCardType,
    EMerchantErrorPgType,
    EMerchantKycType,
    EMerchantMode,
    IMerchantConfigResponse,
    IMerchantState,
} from './types';

export const initialState: IMerchantState = {
    config: {
        contractExpiryDate: '',
        errorPageType: EMerchantErrorPgType.STANDARD,
        isDigitalEnabledForManualAuthentication: '',
        isSalesAssociateTracking: false,
        kycType: EMerchantKycType.ALL,
        merchant: '',
        merchantApiKey: '',
        merchantCardBackgroundColor: '',
        merchantCardBackgroundType: '',
        merchantCardInvertedText: '',
        merchantCardLogo: '',
        merchantCardType: EMerchantCardType.DEFAULT,
        merchantCommonName: '',
        merchantContext: '',
        merchantHash: '',
        merchantId: '',
        merchantLandingPageBackground: '',
        merchantLogoType: '',
        merchantName: '',
        merchantPublicUrl: '',
        merchantStoreId: '',
        merchantTitleEn: '',
        merchantTitleFr: '',
        merchantUrl: '',
        mode: EMerchantMode.UPDATE,
        rcaCode: '',
    },
    error: false,
    loading: false,
};

export const merchantSlice = createSlice({
    extraReducers: (builder: any) => {
        builder.addCase(
            getMerchantConfigAsync.fulfilled,
            (state: IMerchantState, action: PayloadAction<IMerchantConfigResponse>) => {
                const { payload } = action;

                state.loading = false;
                state.error = false;
                state.config = { ...state.config, ...payload };
            }
        );
        builder.addCase(getMerchantConfigAsync.rejected, (state: IMerchantState) => {
            state.loading = false;
            state.error = true;
            state.config = initialState.config;
        });
        builder.addCase(getMerchantConfigAsync.pending, (state: IMerchantState) => {
            state.loading = true;
            state.error = false;
        });
    },
    initialState,
    name: 'merchant',
    reducers: {},
});
