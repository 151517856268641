import React, { PropsWithChildren, Suspense } from 'react';

import { AppLayout } from 'layouts/App';

import { GAService } from './GAService';
import { StepManager } from './StepManager';

const RouteAppLayout: React.FC<PropsWithChildren> = ({ children }) => (
    <AppLayout>
        <StepManager>
            <GAService>
                <Suspense>{children}</Suspense>
            </GAService>
        </StepManager>
    </AppLayout>
);

export default RouteAppLayout;
