import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalyticsServices } from 'hooks/useAnalyticsServices/useAnalyticsServices';

export const GAService: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { pushDataLayer } = useAnalyticsServices();
    const { pathname } = useLocation();

    useEffect(() => {
        pushDataLayer();
    }, [pushDataLayer, pathname]);

    return <>{children}</>;
};
