export const getMerchantParam = (): string => {
    const params = window.location.pathname.split('/');
    const nonMerchantRoute = ['404', 'maintenance'];

    if (params.length === 1 || nonMerchantRoute.includes(params[1])) {
        return '';
    }

    return params[1];
};
