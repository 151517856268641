import { TPartialFairstoneTheme } from '@fairstone/ui/core/providers/DefaultTheme';
import TrackJS from '@fairstone-frontend/utils/core/logs/trackjs';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import { REGION, THEME_API_URL } from 'config/constants';

interface IUseGetMerchantResponse {
    data: TPartialFairstoneTheme | null | undefined;
}
export const useGetMerchantTheme = (merchant: string): IUseGetMerchantResponse => {
    if (isEmpty(merchant)) {
        return { data: null };
    }

    const { data } = useQuery(
        ['useGetMerchantTheme'],
        async () => {
            let response: TPartialFairstoneTheme = {};
            try {
                const { data } = await axios.get<TPartialFairstoneTheme>(
                    `${THEME_API_URL}/${REGION}/merchants/${merchant}/theme.json`
                );
                response = data;
            } catch (e: any) {
                TrackJS.track(e);
            }

            return response;
        },
        { refetchOnWindowFocus: false }
    );
    return { data };
};
