import { TLangTranslations } from '@fairstone/ui/core/providers/Intl/Intl';

//helper function to to retrieve locale from localStorage
export const getLocaleFromLocalStorage = (): TLangTranslations => {
    try {
        const locale = localStorage.getItem('locale');
        const defaultLocale: TLangTranslations = 'en';
        return (locale || defaultLocale) as TLangTranslations;
    } catch (error) {
        return 'en' as TLangTranslations;
    }
};
