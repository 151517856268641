export enum EInitialAuthType {
    MANUAL = 'MANUAL',
    DIGITAL = 'DIGITAL',
    ONFIDO = 'ONFIDO',
}

export enum EFinalAuthType {
    MANUAL = 'MANUAL',
    DIGITAL = 'DIGITAL',
    ONFIDO_ALL_CLEAR = 'O',
    ONFIDO_SUSPECTED = 'O1',
    ONFIDO_REJECTED = 'O2',
    ONFIDO_CAUTION = 'O3',
    ONFIDO_NO_RESPONSE = 'O4',
    ONFIDO_KNOWN_FACES = 'O5',
}

export enum ERespStatusCodes {
    OK = 200,
}

export enum EStepName {
    TELL_US_ABOUT_YOU = 'TELL_US_ABOUT_YOU',
    ADDRESS = 'ADDRESS',
    HOUSING = 'HOUSING',
    TERMS_AND_CONDITION = 'TERMS_AND_CONDITION',
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    DECLINED = 'DECLINED',
    ONFIDO = 'ONFIDO',
    ONFIDO_WAITING = 'ONFIDO_WAITING',
    ONFIDO_VERIFY = 'ONFIDO_VERIFY',
    DIGITAL_VERIFY = 'DIGITAL_VERIFY',
    DIGITAL_VALIDATE = 'DIGITAL_VALIDATE',
    DEBT_RATIO = 'DEBT_RATIO',
    ECOM = 'ECOM',
    IDENTIFICATION = 'IDENTIFICATION',
    ONFIDO_CONFIRMATION = 'ONFIDO_CONFIRMATION',
    ONFIDO_INVALID_DOCUMENT = 'ONFIDO_INVALID_DOCUMENT',
    ECOM_INSUFFICIENT = 'ECOM_INSUFFICIENT',
    ONFIDO_VALIDATE = 'ONFIDO_VALIDATE',
    CANCEL = 'CANCEL',
    ECOM_APPROVED = 'ECOM_APPROVED',
    DIGITAL_APPROVED = 'DIGITAL_APPROVED',
    ONFIDO_APPROVED = 'ONFIDO_APPROVED',
    ECOM_DECLINED = 'ECOM_DECLINED',
    ECOM_PENDING = 'ECOM_PENDING',
    ECOM_IDENTIFICATION = 'ECOM_IDENTIFICATION',
    ROOT = 'ROOT',
    ONFIDO_TELL_US_ABOUT_YOU = 'ONFIDO_TELL_US_ABOUT_YOU',
    DIGITAL_TELL_US_ABOUT_YOU = 'DIGITAL_TELL_US_ABOUT_YOU',
    DIGITAL_ADDRESS = 'DIGITAL_ADDRESS',
    LANG_SELECT = 'LANG_SELECT',
    DIGITAL_HOUSING = 'DIGITAL_HOUSING',
    ERROR = 'ERROR',
    ONFIDO_ADDRESS = 'ONFIDO_ADDRESS',
    ONFIDO_HOUSING = 'ONFIDO_HOUSING',
    SESSION_FAILURE = 'SESSION_FAILURE',
    VERIFICATION_FAILURE = 'VERIFICATION_FAILURE',
}

export const EScreenUrlPaths = {
    ADDRESS: '/address',
    APPROVED: '/approved',
    CANCEL: '/cancel',
    DEBT_RATIO: '/debt-ratio',
    DECLINED: '/declined',
    DIGITAL_ADDRESS: '/digital/address',
    DIGITAL_APPROVED: '/digital/approved',
    DIGITAL_HOUSING: '/digital/housing',
    DIGITAL_TELL_US_ABOUT_YOU: '/digital/tell-us-about-you',
    DIGITAL_VALIDATE: '/digital/validate',
    DIGITAL_VERIFY: '/digital/verify',
    ECOM: '/ecom',
    ECOM_APPROVED: '/ecom/approved',
    ECOM_DECLINED: '/ecom/declined',
    ECOM_IDENTIFICATION: '/ecom/identification',
    ECOM_INSUFFICIENT: '/ecom/insufficient',
    ECOM_PENDING: '/ecom/pending',
    ERROR: '/error',
    HOUSING: '/housing',
    IDENTIFICATION: '/identification',
    LANG_SELECT: '/lang-select',
    ONFIDO: '/onfido',
    ONFIDO_ADDRESS: '/onfido/address',
    ONFIDO_APPROVED: '/onfido/approved',
    ONFIDO_CONFIRMATION: '/onfido/confirmation',
    ONFIDO_HOUSING: '/onfido/housing',
    ONFIDO_INVALID_DOCUMENT: '/onfido/invalid-document',
    ONFIDO_TELL_US_ABOUT_YOU: '/onfido/tell-us-about-you',
    ONFIDO_VALIDATE: '/onfido/validate',
    ONFIDO_VERIFY: '/onfido/verify',
    ONFIDO_WAITING: '/onfido/waiting',
    PATH_DECISION: '/path-decision',
    PENDING: '/pending',
    PRIVACY_BILL: '/privacy-bill',
    ROOT: '/',
    SESSION_FAILURE: '/session-failure',
    TELL_US_ABOUT_YOU: '/tell-us-about-you',
    TERMS_AND_CONDITION: '/terms-and-conditions',
    VERIFICATION_FAILURE: '/verification-failure',
} as const;

export type EScreenUrlPathsType = (typeof EScreenUrlPaths)[keyof typeof EScreenUrlPaths];
