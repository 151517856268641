import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { RadioGroup as MUIRadioGroup, RadioGroupProps } from '@fairstone/ui/core/';

type TRadioProps = RadioGroupProps & {
    name: string;
    children: React.ReactNode;
    control: Control<any>;
};

export const RadioGroup: React.FC<TRadioProps> = ({ children, className, control, name, ...rest }) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, ref, value } }) => (
            <MUIRadioGroup
                className={className}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                value={value}
                {...rest}
            >
                {children}
            </MUIRadioGroup>
        )}
    />
);
