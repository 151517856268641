import React from 'react';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { HourTable } from 'components/HourTable';

import styles from './ErrorSection.module.scss';

export const ErrorSection = (): React.ReactElement => (
    <div className={styles.container}>
        <Typography className={styles.title} variant="h1">
            {t('pages.errors.500.title')}
        </Typography>
        <Typography className={styles.subtitle} variant="h2">
            {t('pages.errors.500.subtitle')}
        </Typography>
        <Typography className={styles.toptext} variant="body2-semibold">
            {t('pages.errors.500.toptext')}
        </Typography>
        <Typography className={styles.maintext} variant="body2">
            {t('pages.errors.500.maintext')}
        </Typography>
        <HourTable />
    </div>
);
