import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { router } from 'routes';
import { setIsAppFlow, setLatestStep } from 'store/redux/modules/application';
import { EScreenUrlPathsType } from 'store/redux/modules/types';
import { findStepNameByURL } from 'utils/routes/routes';

import { getExceptMerchantPath } from './getExceptMerchantPath';

export const redirect = (path: string, dispatch?: Dispatch<AnyAction> | undefined): void => {
    if (dispatch) {
        dispatch(
            setLatestStep(findStepNameByURL(`${`${getExceptMerchantPath(`/${path.trim()}`)}` as EScreenUrlPathsType}`))
        );
        dispatch(setIsAppFlow(true));
    }
    router.navigate(`/${path}`, { replace: true });
};
