import { useLocation } from 'react-router-dom';
import { ProgressBar as ProgressBarCore } from '@fairstone/ui/core/components/ProgressBar';

import { useAppSelector } from 'store/redux/hooks';
import { sessionState } from 'store/redux/modules/session';
import { getPageProgress } from 'utils/url/pageProgress';

export const ProgressBar = (): React.ReactElement => {
    const location = useLocation();
    const { initialAuthenticationType } = useAppSelector(sessionState);
    const pageProgress = getPageProgress(location.pathname || '', initialAuthenticationType);

    if (!pageProgress) return <></>;

    return <ProgressBarCore appearance="page" value={pageProgress} />;
};
