import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useMaintenance } from 'hooks/useMaintenance/useMaintenance';

import { AppLayout } from 'layouts/App';
import ErrorPage from 'pages/Error';
import { redirect } from 'utils/url/redirect';

const AppError = () => {
    throw new Error('Internal Server Error');
};

const App: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [error, setError] = useState(false);
    const { inMaintenance } = useMaintenance();

    useEffect(() => {
        if (inMaintenance && !window.location.pathname.includes('maintenance')) {
            redirect('maintenance');
        }
    }, [inMaintenance]);

    return (
        <ErrorBoundary
            FallbackComponent={() => (
                <AppLayout disableProgressBar>
                    <ErrorPage />
                </AppLayout>
            )}
            onReset={() => {
                setError(false);
            }}
            resetKeys={[error]}
        >
            <>{error ? <AppError /> : children}</>
        </ErrorBoundary>
    );
};

export default App;
